import {MutableRefObject} from 'react';

import {DebitCardStatus} from '@/types/debitCard';
import dayjs from 'dayjs';

import {CashAccountStatus} from '@/lib/constants';

export const isUserEligibleForCashAccount = (status?: CashAccountStatus) => {
  if (!status) {
    return false;
  }
  switch (status) {
    case CashAccountStatus.NotActivated:
    case CashAccountStatus.Uninitialized:
    case CashAccountStatus.OnboardingRequested:
    case CashAccountStatus.OnboardingDataProvided:
    case CashAccountStatus.PendingActivation:
      return true;
    default:
      return false;
  }
};

export const isCashAccountUnderReview = (status?: CashAccountStatus) => {
  if (!status) {
    return false;
  }
  if (status === CashAccountStatus.OnboardingDataProvided) {
    return true;
  }
  return false;
};
export const DEFAULT_CALL_TO_ACTION = 'Open a Cash Account';
export const PENDING_CALL_TO_ACTION = 'Complete Cash Activation';
export const REVIEW_CALL_TO_ACTION = 'Activation is under review';

export const getCashAccountCTALabel = (status?: CashAccountStatus) => {
  if (!status) {
    return DEFAULT_CALL_TO_ACTION;
  }
  switch (status) {
    case CashAccountStatus.NotActivated:
    case CashAccountStatus.Uninitialized:
      return DEFAULT_CALL_TO_ACTION;
    case CashAccountStatus.PendingActivation:
    case CashAccountStatus.OnboardingRequested:
      return PENDING_CALL_TO_ACTION;
    case CashAccountStatus.OnboardingDataProvided:
      return REVIEW_CALL_TO_ACTION;
    default:
      return '';
  }
};

export const isCashAccountActivated = (status?: CashAccountStatus) => {
  if (!status) {
    return false;
  }
  if (status === CashAccountStatus.Activated) {
    return true;
  }
  return false;
};

export const isCashAccountBlocked = (status?: CashAccountStatus) => {
  if (!status) {
    return false;
  }
  if (status === CashAccountStatus.Blocked) {
    return true;
  }
  return false;
};

export const waitForTokenUtil = async (
  ref: MutableRefObject<string | null>,
  extraCondition: boolean = true,
) => {
  while (!ref.current && extraCondition) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }
};

export const isCardRequestedMoreThan30DaysAndNonActivated = (createdAt: string, status: string) => {
  const cardCreatedAt = new Date(createdAt);
  return (
    dayjs(cardCreatedAt).isBefore(dayjs().subtract(1, 'month')) && status === DebitCardStatus.NotActivated
  );
};
