import {getApps, initializeApp} from 'firebase/app';
import {signOut as fbSignOut, getAuth, GoogleAuthProvider, signInAnonymously} from 'firebase/auth';

import {tracking} from '@/services/tracking/TrackingService';
import {getFirebaseToken} from '@/services/user';
import {goTo} from '@/utils/navigation';

import type {ApolloClient} from '@apollo/client';
import type {FirebaseOptions} from 'firebase/app';

const config: FirebaseOptions = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_API_KEY as string,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

let app;
if (!getApps().length) {
  app = initializeApp(config);
} else {
  app = getApps()[0];
}

// =======================
// Auth
// =======================

export const fbAuth = getAuth();

export const getClientUserToken = async () => {
  if (fbAuth.currentUser) {
    try {
      // await fbAuth.currentUser.reload();
      return await fbAuth.currentUser.getIdToken();
    } catch (e) {
      return await signOut({omitGoToLogin: true});
    }
  }
  const existingServerCookie = await getFirebaseToken();
  if (existingServerCookie) {
    return existingServerCookie;
  }
  return null;
};

export const signOut = async (signOutConfig?: {
  apolloClient?: ApolloClient<any>;
  omitGoToLogin?: boolean;
}) => {
  sessionStorage.clear();
  const {omitGoToLogin, apolloClient} = signOutConfig || {};
  tracking.logEvent(tracking.events.log_out(fbAuth.currentUser?.uid));
  await fbSignOut(fbAuth);
  apolloClient && (await apolloClient.clearStore());
  await signInAnonymously(fbAuth);
  if (omitGoToLogin) return;
  goTo('/login');
};

export const provider = new GoogleAuthProvider();
// TODO: Add scopes for Google Auth when integrating inside the signup flow
// TODO: if we want to avoid enter info step. You will need to setup a Google Cloud Project
// https://www.npmjs.com/package/googleapis#working-with-google-cloud-platform-apis
// provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
// provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');

export default config;
