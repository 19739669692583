import type {GetUserQuery, PublishedUserAccountSubscription} from '@/graphql/__generated__/graphql';

type AccountGraphqlResponse = null | GetUserQuery | PublishedUserAccountSubscription;

export const afterGet = (user?: AccountGraphqlResponse) => {
  if (user && 'publishedUserAccount' in user) {
    return user.publishedUserAccount?.account?.balances || null;
  }

  if (user && 'getUser' in user) {
    return user?.getUser?.account?.balances || null;
  }

  return null;
};
