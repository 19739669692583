import {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';

export const useNavigationEvent = (onPathnameChange: () => void) => {
  const {pathname} = useRouter(); // Get current route

  // Save pathname on component mount into a REF
  const savedPathNameRef = useRef(pathname);

  useEffect(() => {
    // If REF has been changed update REF
    if (savedPathNameRef.current !== pathname) {
      onPathnameChange();
      savedPathNameRef.current = pathname;
    }
  }, [pathname, onPathnameChange]);
};
