import {ProgressSpinner} from 'primereact/progressspinner';

import useIsTargetMedia from '@/hooks/useIsTargetMedia';

export default function PageSpinner() {
  const isMobileDevice = useIsTargetMedia('tablet', 'less', true);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        minHeight: `${isMobileDevice ? '70vh' : '100vh'}`,
      }}>
      <ProgressSpinner strokeWidth="3" />
    </div>
  );
}
