export interface MatcherArgs {
  pathname: string;
  prevPathname: string;
  query: NodeJS.Dict<string | string[]>;
}

export interface MatcherResult {
  name: string;
  params: any;
}

export default class EventPathMatcher {
  private readonly paths: Record<string, (args: MatcherArgs) => MatcherResult | undefined>;
  private readonly prevPaths: Record<string, () => MatcherResult | undefined>;

  constructor() {
    this.paths = {
      '/convert': () => ({
        name: 'convert',
        params: {
          status: 'first screen | enter amount screen',
        },
      }),
      '/convert/confirm': () => ({
        name: 'convert',
        params: {
          status: 'second screen | confirm',
        },
      }),
      '/send': () => ({
        name: 'send',
        params: {
          status: 'first screen | select recipient',
        },
      }),
      '/send/select-amount': () => ({
        name: 'send',
        params: {
          status: 'second screen | select amount',
        },
      }),
      '/send/confirm': () => ({
        name: 'send',
        params: {
          status: 'third screen | confirm',
        },
      }),
      '/withdraw/select-amount': () => ({
        name: 'withdraw',
        params: {
          status: 'third screen | select amount',
        },
      }),
      '/withdraw/confirm': () => ({
        name: 'withdraw',
        params: {
          status: 'fifth screen | confirm',
        },
      }),
      '/cash/withdrawal/address': () => ({
        name: 'cashWithdraw',
        params: {
          status: 'first screen | enter address',
        },
      }),
      '/cash/withdrawal/amount': () => ({
        name: 'cashWithdraw',
        params: {
          status: 'second screen | select amount',
        },
      }),
      '/country-not-available': () => ({
        name: 'signup',
        params: {
          status: 'not available in your country',
        },
      }),
      '/asset-select': args => {
        switch (args.query.tx) {
          case 'deposit':
            return {
              name: 'deposit',
              params: {
                status: 'first screen | select currency',
              },
            };
          case 'withdraw':
            return {
              name: 'withdraw',
              params: {
                status: 'first screen | select currency',
              },
            };
          default:
            return undefined;
        }
      },
    };
    this.prevPaths = {
      '/convert/confirm': () => ({
        name: 'convert',
        params: {
          status: 'third screen | initiated',
        },
      }),
      '/send/confirm': () => ({
        name: 'send',
        params: {
          status: 'fourth screen | payment successful',
        },
      }),
      '/kyc/explained': () => ({
        name: 'signup',
        params: {
          status: 'ninth screen | home screen, signup complete',
        },
      }),
    };
  }

  /**
   * Currently match supports the following cases:
   * - A direct path match.
   * - A direct path and query match. Where you need different events depending on both. The /asset-select/tx=type is a good example of that.
   * - A path match where the event depends on the previous path. To deal with cases where the current path is too generic
   *   to determine what event name makes sense.
   * - A partial path match. To deal with cases where the path has a variable in it, for example /deposit/{currency}.
   *
   * Some events will not be possible to infer from the path, in those cases you have to add the tracking in the
   * component/page itself.
   */
  match(args: MatcherArgs): MatcherResult | undefined {
    const eventFunc = this.paths[args.pathname];
    if (eventFunc) {
      return eventFunc(args);
    }
    const prevPathEventFunc = this.prevPaths[args.prevPathname];
    if (prevPathEventFunc) {
      return prevPathEventFunc();
    }
    if (args.pathname.includes('/deposit')) {
      return {
        name: 'deposit',
        params: {
          status: 'second screen | deposit address',
        },
      };
    } else if (args.pathname.includes('/withdraw')) {
      return {
        name: 'withdraw',
        params: {
          status: 'second screen | enter wallet address',
        },
      };
    }
  }
}
