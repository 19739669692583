import type {CardDetails as DebitCardDetails} from '@/graphql/__generated__/graphql';

export {
  CardStatus as DebitCardStatus,
  CardType as DebitCardType,
  CardBrand as DebitCardBrand,
  CardStatusToUpdate as UpdateDebitCardStatus,
} from '@/graphql/__generated__/graphql';

export type {DebitCardDetails};

export interface CardWidget {
  onClick: () => Promise<void>;
  card?: DebitCardDetails | null;
  title: string;
  CTALabel: string;
  isLoading: boolean;
  outOfStock?: boolean;
}
