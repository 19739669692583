import {Toast} from 'primereact/toast';

import type {ToastProps} from 'primereact/toast';
import type {MutableRefObject} from 'react';

type ToastNotificationProps = ToastProps & {
  toastRef?: MutableRefObject<Toast | null>;
};

export default function ToastNotification({toastRef, ...primeToastProps}: ToastNotificationProps) {
  return <Toast ref={toastRef} {...primeToastProps} />;
}
