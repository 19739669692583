import {classNames} from 'primereact/utils';

import {
  DISABLED,
  ERROR_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TEXT_COLOR,
  TEXT_LIGHT_COLOR,
  WHITE_COLOR,
} from '@/utils/colors';

import type {CSSProperties, MouseEventHandler} from 'react';

interface IconProps {
  name: string;
  role?: string;
  className?: string;
  fontSize?: number;
  onClick?: MouseEventHandler;
  color?: keyof typeof ICON_COLORS;
  style?: CSSProperties;
  rawColorString?: string;
}

export const ICON_COLORS = {
  DEFAULT: 'inherit',
  WHITE: WHITE_COLOR,
  PRIMARY: PRIMARY_COLOR,
  SECONDARY: SECONDARY_COLOR,
  TEXT: TEXT_COLOR,
  TEXT_LIGHT: TEXT_LIGHT_COLOR,
  ERROR: ERROR_COLOR,
  DISABLED: DISABLED,
} as const;

export default function Icon({
  name,
  role,
  className,
  color = 'DEFAULT',
  fontSize = 1.8,
  onClick,
  style,
  rawColorString,
}: IconProps) {
  const classnames = classNames(className, {[`icon-${name}`]: name});
  const finalStyle = {...style, fontSize: `${fontSize}rem`, color: rawColorString || ICON_COLORS[color]};
  return (
    <i
      aria-hidden="true"
      className={classnames}
      onClick={onClick}
      role={role || `${name}-icon`}
      style={finalStyle}
    />
  );
}
