/**
 * Returns the last element in `arr` that satisfies `predicate`, or `undefined` if none do.
 */
export function findLast(arr: any[], predicate: (page: string) => boolean) {
  // Ensure the array is actually an array and predicate is a function
  if (!Array.isArray(arr)) {
    throw new TypeError('First argument must be an array');
  }
  if (typeof predicate !== 'function') {
    throw new TypeError('Second argument must be a function');
  }

  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) {
      return arr[i];
    }
  }
  return undefined;
}
