import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import dynamic from 'next/dynamic';

import type {DialogPropType} from '@/components/ui/dialogs/Dialog/Dialog';
import type {ReactNode} from 'react';

type ExternalDialogConfigType = Omit<DialogPropType, 'visible' | 'onHide'>;

const DynamicDialog = dynamic(() => import('@/components/ui/dialogs/Dialog/Dialog'));

type DialogContextType = {
  setDialog: (config: ExternalDialogConfigType | null) => void;
  isVisibleDialog: boolean;
  setIsVisibleDialog: (isVisible: boolean) => void;
  hideDialog: () => void;
  setDialogBgColor: (color: string) => void;
};

export const DialogContext = createContext<DialogContextType>({
  setDialog: () => null,
  isVisibleDialog: false,
  setIsVisibleDialog: () => null,
  hideDialog: () => null,
  setDialogBgColor: () => null,
});

export const useDialogContext = () => useContext(DialogContext);

export const DialogProvider = ({children}: {children: ReactNode}) => {
  const [dialogToDisplay, setDialogToDisplay] = useState<ExternalDialogConfigType | null>(null);
  const [isVisibleDialog, setIsVisibleDialog] = useState<boolean>(false);
  const [dialogBgColor, setDialogBgColor] = useState<string | undefined>(undefined);

  const setDialog = useCallback((config: ExternalDialogConfigType | null) => {
    if (config) {
      setDialogToDisplay(config);
      setIsVisibleDialog(Boolean(config));
    }
  }, []);

  const hideDialog = useCallback(() => {
    setIsVisibleDialog(false);
    // Give time to close animation
    setTimeout(() => setDialog(null), 200);
  }, [setDialog]);

  // This will hide the dialog when user presses the Back Button from the browser
  useEffect(() => {
    window.addEventListener('popstate', hideDialog);
    return () => {
      window.removeEventListener('popstate', hideDialog);
    };
  }, [hideDialog]);

  return (
    <DialogContext.Provider
      value={{setDialog, hideDialog, isVisibleDialog, setIsVisibleDialog, setDialogBgColor}}>
      {dialogToDisplay && (
        <DynamicDialog
          {...dialogToDisplay}
          bgColor={dialogBgColor || dialogToDisplay.bgColor}
          visible={isVisibleDialog}
          onHide={hideDialog}
        />
      )}
      {children}
    </DialogContext.Provider>
  );
};
