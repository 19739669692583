import {useCallback, useEffect, useState} from 'react';

import {ApolloQueryResult, LazyQueryExecFunction, OperationVariables} from '@apollo/client';

type UseServerClientEntityMergeArgs = {
  serverSideData?: any;
  hydratatedData?: any;
  subData: any;
  afterGet: Function;
  lazyQuery?:
    | LazyQueryExecFunction<any, any>
    | ((variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>);
};

function useEntityServerClientMerge<TEntity>({
  serverSideData,
  hydratatedData,
  subData,
  afterGet,
  lazyQuery,
}: UseServerClientEntityMergeArgs): [TEntity, () => Promise<TEntity | null>] {
  const [entity, setEntity] = useState(hydratatedData || serverSideData);

  // in case entity is managed by a subscription
  // this effect will update the entity when either subscription data changes or serverData is updated
  useEffect(() => {
    setEntity(subData || hydratatedData || serverSideData);
  }, [setEntity, subData, serverSideData, hydratatedData]);

  // in case entity is managed by a lazy query
  // this function will update the entity when the query data is executed
  const fetchAndSetEntity = useCallback(async () => {
    if (hydratatedData) {
      const result = await lazyQuery?.();
      return afterGet(result?.data);
    }
    const result = await lazyQuery?.();
    if (!result?.data) {
      return null;
    }
    setEntity(result.data);
    return afterGet(result.data);
  }, [afterGet, hydratatedData, lazyQuery]);

  const afterDataEntity = afterGet(entity);

  return [afterDataEntity, fetchAndSetEntity];
}

export default useEntityServerClientMerge;
