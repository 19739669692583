export async function getFirebaseToken() {
  try {
    const response = await fetch(`/api/user`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const token = await response.json();
    return token || null;
  } catch (error) {
    return null;
  }
}

export async function setFirebaseToken(token: string | null) {
  try {
    const response = await fetch('/api/user', {
      method: token ? 'POST' : 'DELETE',
      body: token ? JSON.stringify({token}) : undefined,
    });

    if (!response.ok) {
      throw new Error('Failed to set token');
    }

    return true;
  } catch (error) {
    //...
    return false;
  }
}
