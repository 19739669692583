import {useEffect} from 'react';

import {useAuthenticationServices} from '@/hooks/login/useAuthenticationServices';
import {setUserInfoStorage} from '@/utils/user-info';

function UserInfo() {
  const {userService} = useAuthenticationServices();

  useEffect(() => {
    const getUserInfo = async () => {
      const location = await userService.getUserLocationAndIP();
      setUserInfoStorage(location?.geo, location?.ip);
    };

    getUserInfo();
  }, [userService]);

  return null;
}

export default UserInfo;
