import Router from 'next/router';

import {WEBPAGE_URL} from '@/constants';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

export const goTo = (newPath: string, as?: string, options?: TransitionOptions) =>
  Router.push(newPath, as, options);

export const goToKastaWeb = () => (window.location.href = WEBPAGE_URL);
