import {useEffect, useState} from 'react';

function useIsTouchDevice() {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const isTouchEnabled =
      'ontouchstart' in window ||
      navigator?.maxTouchPoints > 0 ||
      window.matchMedia('(pointer:coarse)').matches;
    setIsTouchDevice(isTouchEnabled);
  }, []);

  return isTouchDevice;
}

export default useIsTouchDevice;
