export default class ReferralService {
  private readonly key: string;

  constructor() {
    this.key = 'referrer';
  }

  setReferrer(referrer: string) {
    window?.sessionStorage?.setItem(this.key, referrer);
  }

  getReferrer() {
    return window?.sessionStorage?.getItem(this.key);
  }

  clearReferrer() {
    window?.sessionStorage?.removeItem(this.key);
  }
}

export const referralService = new ReferralService();
