import {BigNumber} from 'bignumber.js';

import {EXTRA_INTL_CURRENCY_SYMBOLS} from '@/constants';

import {Balances, Currency, ExchangeRates} from '../graphql/__generated__/graphql';
import {getConvertedAmount} from './amounts';

export type getConvertedBalancesArgs = {
  balances: Balances | null;
  to?: Currency | null;
  exchangeRates: ExchangeRates | null;
};

export const isValidCurrency = (currencyToCheck: string) => {
  return Object.values(Currency).includes(currencyToCheck as Currency);
};

export function getConvertedBalances({balances, to = Currency.Usd, exchangeRates}: getConvertedBalancesArgs) {
  if (
    typeof balances !== 'object' ||
    balances === null ||
    typeof exchangeRates !== 'object' ||
    exchangeRates === null
  ) {
    return 0;
  }

  let accumulator = new BigNumber(0);

  return Object.keys(balances).reduce((acc, currency) => {
    const currentBalance = new BigNumber(
      getConvertedAmount({
        amount: balances[currency as keyof Balances] || 0,
        from: currency,
        to,
        exchangeRates,
      }),
    );
    return currentBalance.plus(acc);
  }, accumulator);
}

export function getCurrencySymbol(currency: Currency) {
  if (Object.keys(EXTRA_INTL_CURRENCY_SYMBOLS).includes(currency)) {
    return EXTRA_INTL_CURRENCY_SYMBOLS[currency];
  }

  try {
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(0);
    return formattedValue.replace(/\.|0|,/g, '');
  } catch (error) {
    return currency;
  }
}
