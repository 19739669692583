import {useMemo} from 'react';

import {AssetType, Currency} from '@/graphql/__generated__/graphql';
import {Asset, DynamicAssetValues, SupportedAssets} from '@/types/assets';
import {BigNumber} from 'bignumber.js';

import {
  environments,
  ONRAMP_DEMO_SYMBOL,
  ONRAMP_PROD_SYMBOL,
  RAMP_DEMO_SYMBOL,
  RAMP_PROD_SYMBOL,
} from '@/constants';
import {useAppConfig} from '@/contexts/AppConfigContext';
import {configService} from '@/services/configService';

import type {Balances, BlockchainAsset, ExchangeRates} from '@/graphql/__generated__/graphql';

import {getConvertedAmount, getFormattedAmount, getFormattedPercentage} from './amounts';

type KeyOfBalances = keyof Balances;

type BaseGetAssetsListArgs = {
  balances: Balances | null;
  displayCurrency?: Currency;
  exchangeRates: ExchangeRates;
  availableAssets?: BlockchainAsset[] | null;
  widgetOnRamp: 'network' | 'money';
  widgetOffRamp: 'network' | 'money';
};

type getAssetsListArgs = BaseGetAssetsListArgs & {
  supportedBlockchainAssets: BlockchainAsset[];
};

type getAssetDynamicValuesArg = {
  currency: Currency;
  amount: number;
  displayCurrency: Currency;
  exchangeRates: ExchangeRates;
  supportedBlockchainAssets: BlockchainAsset[];
  widgetOnRamp: 'network' | 'money';
  widgetOffRamp: 'network' | 'money';
};

type getAssetsChartArgs = {
  destinationCurrency: Currency;
  data: any;
  supportedBlockchainAssets: BlockchainAsset[];
};

type getAssetChartDetailsArgs = {
  sourceCurrency: Currency;
  destinationCurrency: Currency;
  data: any;
  supportedBlockchainAssets: BlockchainAsset[];
};

type chartItem = {
  amount: number;
};

type getChartDomainArgs = {
  chartData: Array<chartItem>;
  coefficient: number;
};

export function transformSupportedAssets(supportedAssets: BlockchainAsset[]) {
  return supportedAssets?.filter(Boolean).reduce((acc: {[key: string]: BlockchainAsset}, blockchainAsset) => {
    const assetKey = blockchainAsset.currency as Currency;
    acc[assetKey] = blockchainAsset;
    return acc;
  }, {});
}

export function getAssetsList({
  balances,
  displayCurrency = Currency.Usd,
  exchangeRates,
  supportedBlockchainAssets,
  widgetOnRamp,
  widgetOffRamp,
}: getAssetsListArgs): Asset[] {
  if (
    typeof balances !== 'object' ||
    balances === null ||
    typeof exchangeRates !== 'object' ||
    exchangeRates === null ||
    typeof supportedBlockchainAssets !== 'object' ||
    supportedBlockchainAssets === null
  ) {
    return [];
  }
  return supportedBlockchainAssets
    .filter((item: BlockchainAsset) => balances[item.currency as KeyOfBalances] !== undefined)
    .map(({id, ...item}) => ({
      ...getAssetDynamicValues({
        currency: item.currency,
        amount: Number(balances[item.currency as KeyOfBalances]) || 0,
        displayCurrency,
        exchangeRates,
        supportedBlockchainAssets,
        widgetOnRamp,
        widgetOffRamp,
      }),
      id,
      assetId: id,
      ...item,
    }))
    .sort((a: any, b: any) => {
      // Force KASTA to be always the 1st crypto at the asset list
      if (b.currency === Currency.Kasta) {
        return 1;
      }
      return b.convertedAmount - a.convertedAmount;
    }) as Asset[];
}

export function getAssetDynamicValues({
  currency,
  amount,
  displayCurrency,
  exchangeRates = {},
  supportedBlockchainAssets,
  widgetOnRamp,
  widgetOffRamp,
}: getAssetDynamicValuesArg): DynamicAssetValues {
  if (!currency || (!amount && amount !== 0)) {
    return {} as DynamicAssetValues;
  }

  // There are chances that buy and sell sdks are not the same for the same user
  // that is why we store the symbols separately
  const getRampSdkDemoSymbols = (sdk: 'network' | 'money') =>
    sdk === 'network' ? RAMP_DEMO_SYMBOL : ONRAMP_DEMO_SYMBOL;
  const getRampSdkProdSymbols = (sdk: 'network' | 'money') =>
    sdk === 'network' ? RAMP_PROD_SYMBOL : ONRAMP_PROD_SYMBOL;
  const getRampSymbolBySdk = (sdk: 'network' | 'money') =>
    configService.getEnvironment() === environments.STAGING
      ? getRampSdkDemoSymbols(sdk)
      : getRampSdkProdSymbols(sdk);

  const BUY_SYMBOL = getRampSymbolBySdk(widgetOnRamp);
  const SELL_SYMBOL = getRampSymbolBySdk(widgetOffRamp);

  const convertedAmount = getConvertedAmount({
    amount,
    from: currency,
    to: displayCurrency,
    exchangeRates,
  });
  return {
    amount,
    amountFormatted: getFormattedAmount({
      currency,
      value: amount,
      supportedBlockchainAssets,
    }),
    convertedAmount,
    convertedAmountFormatted: getFormattedAmount({
      currency: displayCurrency,
      value: convertedAmount as number,
      supportedBlockchainAssets,
    }),
    image: getCurrencyImage(currency),
    // @ts-expect-error
    buySymbol: BUY_SYMBOL[currency],
    // @ts-expect-error
    sellSymbol: SELL_SYMBOL[currency],
  };
}

export function getCurrencyImage(currency: string, invertKasta?: boolean) {
  if (currency.toUpperCase() === Currency.Kasta && invertKasta) {
    return `${process.env.NEXT_PUBLIC_CDN_URL}/public/currencies/KASTA-INVERTED.png`;
  }
  return `${process.env.NEXT_PUBLIC_CDN_URL}/public/currencies/${currency}.png`;
}

export function useSupportedAssets({
  widgetOnRamp,
  widgetOffRamp,
  balances,
  displayCurrency = Currency.Usd,
  exchangeRates,
  availableAssets = [],
}: BaseGetAssetsListArgs): SupportedAssets {
  const {appConfig} = useAppConfig();
  const {currenciesBlackList} = appConfig;
  const purifiedAvailableAssets = availableAssets?.filter(
    asset => !currenciesBlackList?.includes(asset?.currency),
  );

  const assetsList = useMemo(() => {
    if (!purifiedAvailableAssets || !purifiedAvailableAssets?.length) {
      return [];
    }
    return getAssetsList({
      balances,
      displayCurrency,
      exchangeRates,
      supportedBlockchainAssets: purifiedAvailableAssets,
      widgetOnRamp,
      widgetOffRamp,
    });
  }, [balances, displayCurrency, exchangeRates, purifiedAvailableAssets, widgetOnRamp, widgetOffRamp]);

  return useMemo(
    () =>
      assetsList.reduce(
        (acc, asset: Asset) => {
          if (isCashCurrency({type: asset.type})) {
            acc.cashAssets.push(asset);
          } else {
            acc.cryptoAssets.push(asset);
          }
          return acc;
        },
        {cryptoAssets: [] as Asset[], cashAssets: [] as Asset[]},
      ),
    [assetsList],
  );
}

export const getAsset = (supportedBlockchainAssets: BlockchainAsset[], currency: Currency) =>
  supportedBlockchainAssets.find(asset => asset.currency === currency) as BlockchainAsset;

export const getAssetsChart = ({
  destinationCurrency,
  data,
  supportedBlockchainAssets,
}: getAssetsChartArgs) => {
  if (!data || data.length === 0) {
    return null;
  }
  const assets = Object.keys(data[0].balances).filter(key => key !== '__typename');
  const assetsChart = {};
  assets.forEach(item => {
    // @ts-expect-error
    assetsChart[item] = getAssetChartDetails({
      sourceCurrency: item as Currency,
      destinationCurrency,
      data,
      supportedBlockchainAssets,
    });
  });
  return assetsChart;
};

export const getAssetChartDetails = ({
  sourceCurrency,
  destinationCurrency,
  data,
  supportedBlockchainAssets,
}: getAssetChartDetailsArgs) => {
  if (!data || data.length === 0) {
    return null;
  }

  let initialAmount = 0;
  let lastAmount;

  // @ts-expect-error
  const chartData = data.map((item, index) => {
    const sourceAmount = Number(item.balances[sourceCurrency]);
    const exchangeRate =
      (item.exchangeRates && item.exchangeRates[destinationCurrency + sourceCurrency]) || 0;
    const destinationAmount = sourceAmount * exchangeRate;
    if (sourceAmount && !initialAmount) {
      initialAmount = destinationAmount;
    }
    if (index === data.length - 1) {
      lastAmount = destinationAmount;
    }
    return {
      amount: destinationAmount,
      currency: destinationCurrency,
      sourceAmount: sourceAmount,
      sourceCurrency: sourceCurrency,
      timestamp: new Date(item.timestamp),
      formattedAmount: getFormattedAmount({
        currency: destinationCurrency,
        value: destinationAmount,
        supportedBlockchainAssets,
      }),
      formattedCryptoAmount:
        sourceCurrency &&
        getFormattedAmount({
          currency: sourceCurrency,
          value: sourceAmount,
          supportedBlockchainAssets,
        }),
    };
  });

  // @ts-expect-error
  const result = lastAmount - initialAmount;

  return {
    chartData,
    chartDomain: getChartDomain({chartData, coefficient: 0.05}),
    total: lastAmount,
    totalFormatted: getFormattedAmount({
      currency: destinationCurrency,
      value: lastAmount,
      supportedBlockchainAssets,
    }),
    result,
    resultFormatted: getFormattedAmount({
      currency: destinationCurrency,
      value: result,
      supportedBlockchainAssets,
    }),
    resultPercentageFormatted: `${result > 0 ? '+' : ''}${getFormattedPercentage({
      value: initialAmount ? result / initialAmount : 0,
    })}`,
  };
};

export const getChartDomain = ({chartData = [], coefficient = 1}: getChartDomainArgs) => {
  let lowestYAxisValue = 0;
  let highestYAxisValue = 0;
  if (!chartData || chartData.length === 0) {
    return null;
  }

  chartData
    .filter(item => typeof item.amount === 'number')
    .forEach((item, index) => {
      if (index === 0) {
        lowestYAxisValue = highestYAxisValue = item.amount;
      }
      if (item.amount < lowestYAxisValue) {
        lowestYAxisValue = item.amount;
      }
      if (item.amount > highestYAxisValue) {
        highestYAxisValue = item.amount;
      }
    });
  if (!lowestYAxisValue && !highestYAxisValue) {
    return null;
  }

  if (typeof coefficient !== 'number' || coefficient >= 1 || coefficient <= 0) {
    return {y: [lowestYAxisValue, highestYAxisValue]};
  }
  return {
    y: [
      new BigNumber(lowestYAxisValue).multipliedBy(1 - coefficient).toNumber(),
      new BigNumber(highestYAxisValue).multipliedBy(1 + coefficient).toNumber(),
    ],
  };
};

export const isCashCurrency = ({currency, type}: {currency?: string; type?: AssetType | null}) => {
  const uppercaseCurrency = currency?.toUpperCase();
  return (
    type === AssetType.Cash ||
    uppercaseCurrency === Currency.Eur ||
    uppercaseCurrency === Currency.Usd ||
    uppercaseCurrency === Currency.Sek
  );
};

export const getDecimalsByAsset = (asset: BlockchainAsset) => parseInt(asset.decimalDigits, 10);
