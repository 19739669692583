import {useEffect} from 'react';
import {useRouter} from 'next/router';

import {useComplianceContext} from '@/contexts/ComplianceContext';
import {useToastNotificationContext} from '@/contexts/ToastNotificationContext';

import {DIRECT_ACCESS_ROUTES} from '../PrivateRouteSafetyControl';

export function MandatoryKycControl() {
  const router = useRouter();
  const {currentKycInfo, canSkipKyc} = useComplianceContext();
  const {setToastNotification} = useToastNotificationContext();

  // This effect purpose is to redirect user to KYC page
  // if the user has a mandatory KYC flow and it hasn't been completed yet
  useEffect(() => {
    const isRouteWithoutControl = [...DIRECT_ACCESS_ROUTES, '/kyc', '/2fa'].some(route =>
      router.pathname.includes(route),
    );

    // We avoid control if
    if (
      // compliance info is not loaded
      !currentKycInfo ||
      // user has already completed the mandatory KYC flow
      canSkipKyc ||
      // user is in a free of control route
      isRouteWithoutControl
    ) {
      return;
    }
    router.push(`/kyc`);
  }, [canSkipKyc, currentKycInfo, router, setToastNotification]);

  return null;
}
