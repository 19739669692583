import MainButton from '@/components/ui/buttons/MainButton/MainButton';
import DialogBody from '@/components/ui/dialogs/DialogBody/DialogBody';

import s from './CashSCADialogBody.module.scss';

export default function CashSCADialogBody({
  description,
  onCTAClick,
  onCancel,
}: {
  description: string;
  onCTAClick: Function;
  onCancel: Function;
}) {
  const dialogExtraButtons = [
    <div key="cta-button" className={s.wrapper}>
      <MainButton label="Complete" rightIcon="arrow-right" onClick={onCTAClick} size="SMALL" fullWidth />
      <MainButton
        className={s.button}
        iconFontSize={2.4}
        label="Close"
        size="SMALL"
        fullWidth
        onClick={onCancel}
        variant="SECONDARY"
      />
    </div>,
  ];

  return (
    <DialogBody
      title="Strong Customer Authentication"
      warning={description}
      icon="security"
      extraButtons={dialogExtraButtons}
    />
  );
}
