import {useEffect, useRef} from 'react';

import {getZendeskToken} from '@/graphql/queries';
import {useQuery} from '@apollo/client';

import {ERROR_LEVEL} from '@/constants';
import {zendeskService} from '@/services/support/ZendeskService';
import {tracking} from '@/services/tracking/TrackingService';

import type {User} from '@/types/user';
import type {User as FbUser} from 'firebase/auth';

export const useZendeskLogin = ({fbUser, kastaUser}: {fbUser: FbUser | null; kastaUser: User | null}) => {
  const zendeskUserLoggedIn = useRef(false);

  useQuery(getZendeskToken, {
    skip: !kastaUser || !fbUser || fbUser.isAnonymous || zendeskUserLoggedIn.current,
    onCompleted({createZendeskToken}) {
      if (!createZendeskToken) {
        throw new Error(`Failed to obtain Zendesk token`);
      }

      zendeskService.loginUserOnMessenger(createZendeskToken);
      zendeskUserLoggedIn.current = true;
    },
    onError(error: any) {
      tracking.logError({
        error_message: error.message || JSON.stringify(error),
        error_level: ERROR_LEVEL.CRITICAL,
        error_message_id: 'zendesk/kasta/unable-to-get-zendesk-token',
      });
    },
  });

  /*
   * LOGOUT
   *
   * If the user is tagged as logged in but now Firebase says they're anonymous,
   * that implies that they've logged out of the PWA. We should log them out of Zendesk as well.
   */

  useEffect(() => {
    if (!fbUser || !fbUser.isAnonymous || !zendeskUserLoggedIn.current) {
      return;
    }

    logoutOfZendesk();
    zendeskUserLoggedIn.current = false;
  }, [fbUser, kastaUser]);
};

const logoutOfZendesk = () => {
  try {
    zendeskService.logoutUserOnMessenger();
  } catch (error: any) {
    tracking.logError({
      error_message: error.message || JSON.stringify(error),
      error_level: ERROR_LEVEL.CRITICAL,
      error_message_id: 'zendesk/kasta/unable-to-log-user-out',
    });
  }
};
