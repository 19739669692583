import type {
  GetImprovedExchangeRatesQuery,
  PublishedExchangeRatesSubscription,
} from '@/graphql/__generated__/graphql';

type ExchangeRatesGraphqlResponse =
  | GetImprovedExchangeRatesQuery
  | PublishedExchangeRatesSubscription
  | undefined;

export const afterGet = (data: ExchangeRatesGraphqlResponse) => {
  if (!data) return {};
  if ('getImprovedExchangeRates' in data) return data?.getImprovedExchangeRates || {};
  if ('publishedExchangeRates' in data) return data?.publishedExchangeRates || {};
  return {};
};
