import {useEffect, useMemo} from 'react';

import {KycStatus} from '@/types/compliance';

import {useUser} from '@/contexts/UserContext';
import {goTo} from '@/utils/navigation';

function useBlockAccountControl() {
  const {user} = useUser();

  const isRedFinalKycStatus = useMemo(() => {
    return user?.kyc?.status === KycStatus.RedFinal;
  }, [user]);

  useEffect(() => {
    if (!isRedFinalKycStatus) {
      return;
    }

    goTo('/account-blocked');
  }, [isRedFinalKycStatus]);
}

export default useBlockAccountControl;
