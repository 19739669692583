import React from 'react';

import {tracking} from '@/services/tracking/TrackingService';

import type {AnalyticsEvent} from '@/types/tracking';

type ClickTrackingWrapperProps<T extends React.ComponentType<any>> = {
  WrappedComponent: T | React.ElementType;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  analyticEvent?: AnalyticsEvent;
} & React.ComponentProps<T>;

export const ClickTrackingWrapper = <TComponentType extends React.ComponentType<any>>({
  WrappedComponent,
  children,
  onClick,
  analyticEvent,
  ...restWrappedComponentProps
}: ClickTrackingWrapperProps<TComponentType>) => {
  const _onClick = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      analyticEvent && tracking.logEvent(analyticEvent);
      onClick && onClick(e);
    } catch (e: any) {
      tracking.logTrackingError(e?.message);
      throw e;
    }
  };
  return (
    <WrappedComponent onClick={_onClick} {...restWrappedComponentProps}>
      {children}
    </WrappedComponent>
  );
};
