export default class ZendeskService {
  loginUserOnMessenger(token: string) {
    window.zE('messenger', 'loginUser', async function (callback: Function) {
      callback(token);
    });
  }

  logoutUserOnMessenger() {
    window.zE('messenger', 'logoutUser');
  }

  openMessenger() {
    window.zE('messenger', 'open');
  }

  getUnreadMessagesCount(callback: (count: number) => void) {
    window.zE('messenger:on', 'unreadMessages', callback);
  }
}

export const zendeskService = new ZendeskService();
