export const isBrowser = () => typeof window !== 'undefined';

export const isPwaInstalled = () => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

export const isFocusedElement = ({id, element}: {id?: string; element?: HTMLElement}) =>
  (isBrowser() && document.activeElement?.id === id) || (element && element === document.activeElement);

const hasTouchScreen = () => isBrowser() && window.matchMedia('(hover: none) and (pointer: coarse)').matches;

// Devices OS
export const isIOS = () => isBrowser() && /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isAndroid = () => isBrowser() && /Android/.test(navigator.userAgent);
export const isWindows = () => isBrowser() && /Windows/.test(navigator.userAgent);
export const isMac = () => isBrowser() && /Macintosh/.test(navigator.userAgent);

// Window size
export const isMobileMediaQuery = () => window.matchMedia('(max-width: 767px)').matches;
export const isTabletMediaQuery = () =>
  window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches;
export const isDesktopMediaQuery = () => window.matchMedia('(min-width: 1025px)').matches;

// Devices types
export const isMobile = () => isBrowser() && isMobileMediaQuery() && hasTouchScreen();
export const isTablet = () => isBrowser() && isTabletMediaQuery() && hasTouchScreen();
export const isDesktop = () => isBrowser() && isDesktopMediaQuery() && !hasTouchScreen();

// Browsers
export const isChrome = () => isBrowser() && /Chrome/.test(navigator.userAgent);
export const isFirefox = () => isBrowser() && /Firefox/.test(navigator.userAgent);
export const isEdge = () => isBrowser() && /Edg/.test(navigator.userAgent);
export const isOpera = () => isBrowser() && /OPR/.test(navigator.userAgent);
export const isSafari = () => isBrowser() && !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
export const isChromeIOS = () => isBrowser() && !!navigator.userAgent.match(/CriOS/);

// Mix conditionsDevices and Browsers
export const isIOSSafari = () => isIOS() && isSafari();
export const isChromeInIOS = () => isIOS() && isChromeIOS();

export const getBrowserInUse = () => {
  if (isFirefox()) return 'Firefox';
  if (isSafari()) return 'Safari';
  if (isEdge()) return 'Edge';
  if (isOpera()) return 'Opera';
  // Chrome checker must be last of chromiums
  if (isChrome()) return 'Chrome';
  if (isChromeIOS()) return 'iOS Chrome';
  return 'Unknown';
};

export const getBrowserVersion = () => {
  let version = '';
  if (isBrowser()) {
    if (isSafari()) {
      version = navigator.userAgent.split('Version/')[1]?.split(' ')[0]?.split('.')[0];
      return version;
    }

    const userAgent = window.navigator.userAgent;
    // Order matters. Chrome first.
    const browsers = ['Chrome', 'Edge', 'Edg', 'Opera', 'OPR', 'Firefox', 'SamsungBrowser'];
    browsers.forEach(browser => {
      if (userAgent.includes(browser)) {
        version = userAgent.split(`${browser}/`)[1]?.split(' ')[0]?.split('.')[0];
      }
    });
  }
  return version;
};

export const getOperativeSystem = () => {
  if (isIOS()) return 'iOS';
  if (isAndroid()) return 'Android';
  if (isWindows()) return 'Windows';
  if (isMac()) return 'Mac';
  return 'Unknown';
};

// Determine the device type
export const getDeviceType = () => {
  if (isMobile()) {
    return 'Mobile';
  }
  if (isTablet()) {
    return 'Tablet';
  }
  return 'Desktop';
};

export const handleShare = async ({url, text, title}: {text?: string; url?: string; title?: string}) => {
  try {
    await navigator.share({
      title,
      url,
      text,
    });
  } catch (error) {
    console.error(error);
  }
};

export const isDev = process.env.NODE_ENV === 'development';
