import {useMemo} from 'react';

import {useApolloClient} from '@apollo/client';

import {BaseAuthenticationMethod} from '@/services/auth/BaseAuthenticationMethod';
import {EmailAuthenticationService} from '@/services/auth/EmailAuthenticationService';
import {GoogleAuthenticationService} from '@/services/auth/GoogleAuthenticationService';
import {
  AuthenticationMethod,
  AuthenticationMethodType,
  IAuthenticationMethod,
} from '@/services/auth/IAuthenticationMethod';
import {PhoneNumberAuthenticationService} from '@/services/auth/PhoneNumberAuthenticationService';
import {tracking} from '@/services/tracking/TrackingService';
import {UserService} from '@/services/user/UserService';

export const useAuthenticationServices = () => {
  const client = useApolloClient();

  return useMemo(() => {
    const authService = new BaseAuthenticationMethod(tracking, client, '');
    const lastMethod = authService.getLastAuthenticationMethod() as AuthenticationMethodType | undefined;

    const services = {
      [AuthenticationMethod.EMAIL]: new EmailAuthenticationService(tracking, client),
      [AuthenticationMethod.PHONE_NUMBER]: new PhoneNumberAuthenticationService(tracking, client),
      [AuthenticationMethod.GOOGLE]: new GoogleAuthenticationService(tracking, client),
    };

    return {
      userService: new UserService(tracking, client),
      lastAuthService: lastMethod ? (services[lastMethod] as IAuthenticationMethod) : undefined,
      emailService: services[AuthenticationMethod.EMAIL],
      phoneNumberService: services[AuthenticationMethod.PHONE_NUMBER],
      googleService: services[AuthenticationMethod.GOOGLE],
      authService,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
