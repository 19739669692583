import {ERROR_LEVEL} from '@/constants';
import {zendeskService} from '@/services/support/ZendeskService';
import {tracking} from '@/services/tracking/TrackingService';

export const handleContactSupport = () => {
  try {
    zendeskService.openMessenger();
    tracking.logEvent(tracking.events.open_chat_support());
  } catch (error: any) {
    tracking.logError({
      error_message: error.message || JSON.stringify(error),
      error_level: ERROR_LEVEL.CRITICAL,
      error_message_id: 'zendesk/kasta/unable-to-open-messenger',
    });
  }
};
