import Image from 'next/image';

import bgDesktop from '@/public/images/404-bg-desktop.png';
import bgMobile from '@/public/images/404-bg-mobile.png';

import useIsTargetMedia from '@/hooks/useIsTargetMedia';
import {goToKastaWeb} from '@/utils/navigation';
import {handleContactSupport} from '@/utils/support';

import MainButton from '@/components/ui/buttons/MainButton/MainButton';
import Icon from '@/components/ui/Icon';

import s from './ErrorPage.module.scss';

type ErrorPageProps = {
  heading?: string;
  description?: string;
  buttonLabel?: string;
  onClick?: () => void;
};

const getBgImage = (isMobileDevice: boolean | null) => {
  if (isMobileDevice === null) return null;
  return isMobileDevice ? bgMobile : bgDesktop;
};

export default function ErrorPage({heading, description, buttonLabel, onClick}: ErrorPageProps) {
  const isMobileDevice = useIsTargetMedia('tablet', 'less', true);
  const pageBg = getBgImage(isMobileDevice);

  return (
    <>
      <div className={s.wrapper}>
        {pageBg && <Image src={pageBg} priority quality={100} alt="Error page background image" />}

        <div className={s.iconWrapper}>
          <Icon name="kasta" color="PRIMARY" fontSize={isMobileDevice ? 5 : 7} />
        </div>

        <h1>{heading || `Oops, Something's Not Right!`}</h1>
        <p>{description || 'Please, check the URL again or let us take you back to the Kasta home page'}</p>
        <div className={s.buttonRow}>
          <MainButton label={buttonLabel || 'Back to Home page'} onClick={onClick || goToKastaWeb} />
          <MainButton label="Contact Support" onClick={handleContactSupport} variant="SECONDARY" />
        </div>
      </div>
    </>
  );
}
