export {KycStatus} from '@/graphql/__generated__/graphql';

export type ComplianceInfo = {
  countryCodes: string[];
  requirements: ComplianceRequirements[];
  depositLimits: {
    monthly: number;
    perTx: number;
    currency: string;
    weekly: number;
    daily: number;
  };
  flowName: string;
  transferLimits: {
    monthly: number;
    perTx: number;
    currency: string;
    weekly: number;
    daily: number;
  };
  withdrawLimits: {
    monthly: number;
    perTx: number;
    currency: string;
    weekly: number;
    daily: number;
  };
  labels: {
    infoCard?: {
      levelName: string;
      depositLimit: string;
      withdrawLimit: string;
    };
    explainer?: {
      heading: string;
      subtitles: string[];
      previousLevelLimit: string;
    };
  };
  SK: string;
  order: number;
  PK: string;
  featuresRestricted: any[];
  weight: number;
};

export type ComplianceInfoMap = {[key: string]: ComplianceInfo};

export type KycSettingsInfo = ComplianceInfo | null | undefined;

export type ComplianceRequirements = 'Personal ID' | 'Selfie' | 'Proof of Address' | 'Questionnaire';

export type FeaturesDisplayInfo = {
  overline: string;
  title: string;
  caption: {
    label: string;
    value: string;
  }[];
};

export type RequirementsDisplayInfo = {
  title: string;
  list: {
    label: ComplianceRequirements;
  }[];
};

export type SUMSUB_REVIEW_ANSWER = 'GREEN' | 'RED' | null;
export type SUMSUB_REVIEW_STATUS = 'pending' | 'completed' | null;
