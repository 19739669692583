import {BlockchainAsset, Currency, ExchangeRates, KycStatus} from '@/graphql/__generated__/graphql';

import {
  KYC_STATUS_AVAILABLE_VERIFICATION,
  KYC_STATUS_VERIFICATION_IN_PROCESS,
  KycLevel,
  LEGACY_APP_USERS_KYC_STATUS,
} from '@/constants';
import {getConvertedAndFormattedValues, getFormattedAmount} from '@/utils/amounts';

import type {User} from '@/graphql/__generated__/graphql';
import type {ComplianceInfo, ComplianceInfoMap, KycSettingsInfo} from '@/types/compliance';
import type {EmptyObject} from '@/types/generics';
import type {SettingsBadgeConfig} from '@/types/settings';

export const getNextKycAvailableFlow = (complianceSettings: ComplianceInfoMap | null, user: User) => {
  if (!complianceSettings) return null;
  const currentKcy = user.kyc?.level;
  const isStatusCompleted = user.kyc?.status === KycStatus.Green;
  // "level-0": Object {}
  // ​"level-1": null
  // ​"level-2": null
  // Return the last level from the compliance settings object
  const lastComplianceLevel = Object.values(complianceSettings).filter(Boolean).pop();
  // Ex: ROW#level-0
  const lastLevel = `${lastComplianceLevel?.PK}#${lastComplianceLevel?.SK}`;

  // User has already completed last KYC available
  if ((lastLevel === currentKcy || currentKcy === KycLevel.One) && isStatusCompleted) {
    return null;
  }
  // If next the current level is not completed "green",the current one should be considered the next
  return lastComplianceLevel || null;
};

export const getCurrentKycFlow = (complianceSettings: ComplianceInfoMap | null, user: User) => {
  const currentKcy = user.kyc?.level; // Ex: ROW#level-0, EEA#level-1...
  if (!complianceSettings || !currentKcy) return null;

  // Legacy users has 'ONE' as kyc level
  // We need to return the first level of the compliance settings
  if (currentKcy === LEGACY_APP_USERS_KYC_STATUS) {
    return complianceSettings['level-0'];
  }

  const targetComplianceSettingsKey = currentKcy.split('#')[1];
  return complianceSettings?.[targetComplianceSettingsKey] || null;
};

export const generateKycDisplayInfo = ({
  complianceInfo,
  exchangeRates,
  supportedBlockchainAssets,
  displayCurrency,
}: {
  complianceInfo: ComplianceInfo;
  exchangeRates: ExchangeRates | EmptyObject;
  supportedBlockchainAssets: BlockchainAsset[];
  displayCurrency: Currency;
}) => {
  const {SK, labels} = complianceInfo;
  const levelNumber = SK.split('-')[1];
  const depositLimitCopy = labels.infoCard?.depositLimit;
  const withdrawLimitCopy = labels.infoCard?.withdrawLimit;
  const isDepositLimitCopyNoLimit = depositLimitCopy === 'No limit';
  const isWithdrawLimitCopyNoLimit = withdrawLimitCopy === 'No limit';

  const formattedLimits = {
    depositLimit: isDepositLimitCopyNoLimit
      ? depositLimitCopy
      : getFormattedAmount({currency: Currency.Usd, value: depositLimitCopy}),
    withdrawLimit: isWithdrawLimitCopyNoLimit
      ? withdrawLimitCopy
      : getFormattedAmount({currency: Currency.Usd, value: withdrawLimitCopy}),
  };

  if (!isDepositLimitCopyNoLimit && displayCurrency !== Currency.Usd) {
    const {convertedValueFormatted: convertedDepositLimit} = getConvertedAndFormattedValues({
      // Quantity in backend table is in USD
      sourceCurrency: Currency.Usd,
      destCurrency: displayCurrency,
      value: String(complianceInfo.depositLimits.daily),
      exchangeRates,
      supportedBlockchainAssets,
    });
    formattedLimits.depositLimit = convertedDepositLimit;
  }
  if (!isWithdrawLimitCopyNoLimit && displayCurrency !== Currency.Usd) {
    const {convertedValueFormatted: convertedWithdrawLimit} = getConvertedAndFormattedValues({
      // Quantity in backend table is in USD
      sourceCurrency: Currency.Usd,
      destCurrency: displayCurrency,
      value: String(complianceInfo.withdrawLimits.daily),
      exchangeRates,
      supportedBlockchainAssets,
    });
    formattedLimits.withdrawLimit = convertedWithdrawLimit;
  }

  const features = {
    overline: labels.infoCard?.levelName || `level ${levelNumber}`,
    title: 'Features & limits',
    caption: [
      {
        label: 'Deposit:',
        value: isDepositLimitCopyNoLimit
          ? formattedLimits.depositLimit
          : `${formattedLimits.depositLimit} Daily`,
      },
      {
        label: 'Withdrawal:',
        value: isWithdrawLimitCopyNoLimit
          ? formattedLimits.withdrawLimit
          : `${formattedLimits.withdrawLimit} Daily`,
      },
    ],
  };
  const requirements = {
    title: 'Requirements',
    list: complianceInfo.requirements.map(requirement => {
      return {
        label: requirement,
      };
    }),
  };
  return {
    requirements,
    features,
  };
};

export const getKycInfoCardButtonLabel = (kycStatus: KycStatus | null | undefined) => {
  let copy = 'Verify now';
  if (kycStatus) {
    if (KYC_STATUS_VERIFICATION_IN_PROCESS.includes(kycStatus)) {
      copy = 'Verifying...';
    }
    if (kycStatus === KycStatus.RedRetry) {
      copy = 'Try again';
    }
  }
  return copy;
};

export const getShouldDisplayByItems = ({
  kycStatus,
  kycInfo,
}: {
  kycStatus?: KycStatus | null;
  kycInfo?: KycSettingsInfo;
}) => {
  const itemsToDisplay = {
    shouldDisplaySuccess: false,
    shouldDisplayFailed: false,
    shouldDisplayCTA: false,
  };

  if (kycStatus === KycStatus.RedRetry || kycStatus === KycStatus.RedFinal) {
    itemsToDisplay.shouldDisplayFailed = true;
  }

  if (kycStatus === KycStatus.Green) {
    // If current kyc does not have requirements we don't want to show verified label
    itemsToDisplay.shouldDisplaySuccess = Boolean(kycInfo?.requirements?.length);
  }

  if (
    // if no kycStatus, it means we are displaying
    // the card of a next level of kyc available (pending) not started yet
    !kycStatus ||
    KYC_STATUS_AVAILABLE_VERIFICATION.includes(kycStatus) ||
    KYC_STATUS_VERIFICATION_IN_PROCESS.includes(kycStatus)
  ) {
    itemsToDisplay.shouldDisplayCTA = true;
  }
  return itemsToDisplay;
};

export const getSettingKycBadgeConfig = (userKyc: User['kyc']): SettingsBadgeConfig | undefined => {
  if (!userKyc) {
    return undefined;
  }
  const {status, pending} = userKyc;
  const statusToUse = pending?.status || status;

  if (statusToUse === KycStatus.Green) {
    return {
      text: 'Verified',
      bgColor: 'green',
    };
  }

  if (statusToUse === KycStatus.RedRetry) {
    return {
      text: 'Unverified',
      bgColor: 'red',
    };
  }

  if (statusToUse === KycStatus.OnHold || statusToUse === KycStatus.Pending) {
    return {
      text: 'Verifying',
      bgColor: 'yellow',
    };
  }

  return undefined;
};
