import {ERROR_MESSAGE_ID as PossibleCodes} from '@/types/tracking';

import {ERROR_LEVEL} from '@/constants';

export class ValidationError extends Error {
  code: PossibleCodes;
  level = ERROR_LEVEL.NOTICE;

  constructor({code, message}: {code: PossibleCodes; message: string}) {
    super(message);
    this.name = 'ValidationError';
    this.code = code;
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}
