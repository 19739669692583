import {DocumentNode, useQuery, useSubscription} from '@apollo/client';

import type {OnDataOptions, TypedDocumentNode} from '@apollo/client';

type useSubscribeToEntityArgs<TAfterController, TQueryType, TSubType> = {
  query: TypedDocumentNode<TQueryType> | DocumentNode;
  subscription: DocumentNode;
  waitsFor: boolean;
  controllerTransform: (rawData: TQueryType | TSubType | undefined) => TAfterController;
  onData?: (options: OnDataOptions<any>) => any;
};

export const useSubscribeToEntity = <TAfterController, TQueryType, TSubType>({
  query,
  subscription,
  waitsFor,
  controllerTransform,
  onData = () => {},
}: useSubscribeToEntityArgs<TAfterController, TQueryType, TSubType>) => {
  const {
    loading: queryLoading,
    data: queryData,
    error: queryError,
    refetch: refetchQuery,
  } = useQuery<TQueryType>(query, {
    skip: waitsFor,
  });

  const {
    data: subData,
    error: subError,
    loading: subLoading,
    // We can use the passed QueryType because some subscriptions return the same data as the query
  } = useSubscription<TSubType | TQueryType>(subscription, {skip: waitsFor, onData});

  const entity = subData || queryData;
  const transformedEntity: TAfterController = controllerTransform(entity);

  return {
    entity: transformedEntity,
    loading: subLoading || queryLoading,
    queryLoading,
    subLoading,
    error: subError?.message || queryError?.message || '',
    refetchQuery,
  };
};
