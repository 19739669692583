import {useEffect} from 'react';

import {ERROR_LEVEL} from '@/constants';
import {tracking} from '@/services/tracking/TrackingService';
import {shouldCheckForServiceWorkerUpdate} from '@/utils/time';

import useIsPwaInstalled from '../useIsPwaInstalled';

function useServiceWorkerUpdate() {
  const isPwaInstalled = useIsPwaInstalled();

  useEffect(() => {
    const checkForUpdate = () => {
      if (!('serviceWorker' in navigator)) {
        return;
      }

      try {
        navigator.serviceWorker.ready.then(registration => {
          registration.addEventListener('updatefound', () => {
            const installingWorker = registration.installing;
            if (!installingWorker) {
              return;
            }

            installingWorker.addEventListener('statechange', () => {
              if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                window.location.reload();
              }
            });
          });
        });
      } catch (error: any) {
        tracking.logError({
          error_message: error.message || JSON.stringify(error),
          error_level: ERROR_LEVEL.CRITICAL,
          error_message_id: 'service-worker/kasta/unable-to-check-for-pwa-updates',
        });
      }
    };

    if (isPwaInstalled && shouldCheckForServiceWorkerUpdate()) {
      checkForUpdate();
    }
  }, [isPwaInstalled]);
}

export default useServiceWorkerUpdate;
