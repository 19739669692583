import dynamic from 'next/dynamic';

import {useDialogContext} from '@/contexts/DialogContext';

import {ICON_COLORS} from '@/components/ui/Icon';

import type {ReactNode} from 'react';

import s from './DialogBody.module.scss';

const DynamicMainButton = dynamic(() => import('@/components/ui/buttons/MainButton/MainButton'));
const DynamicIcon = dynamic(() => import('@/components/ui/Icon'));
const DynamicWarningPill = dynamic(() => import('@/components/ui/WarningPill/WarningPill'));

export interface DialogBodyPropType {
  title?: string;
  image?: ReactNode;
  icon?: string;
  iconColor?: keyof typeof ICON_COLORS;
  body?: ReactNode;
  warning?: string;
  closeLabel?: string;
  onClose?: Function;
  closeLabelLeftIcon?: string;
  bodyPlacement?: 'AFTER_CTA' | 'BEFORE_CTA';
  cancelHideDialog?: boolean;
  extraButtons?: ReactNode[];
  buttonsContainerClassName?: string;
}

const DialogBody = ({
  title,
  image,
  icon,
  iconColor = 'PRIMARY',
  body,
  warning,
  closeLabel,
  onClose,
  closeLabelLeftIcon,
  cancelHideDialog,
  bodyPlacement = 'AFTER_CTA',
  extraButtons,
  buttonsContainerClassName,
}: DialogBodyPropType) => {
  const {hideDialog} = useDialogContext();

  const handleClose = async () => {
    !cancelHideDialog && hideDialog();
    onClose && (await onClose());
  };

  const getCallToAction = () => {
    const closeButton = closeLabel ? (
      <DynamicMainButton
        iconFontSize={2.4}
        leftIcon={closeLabelLeftIcon}
        label={closeLabel}
        size="SMALL"
        fullWidth
        variant="SECONDARY"
        onClick={handleClose}
      />
    ) : null;
    if (extraButtons) {
      // TODO: REFACTOR  extraButtons + close button usage
      // stop wrapping buttons inside a div to style the container, maybe decide if using an array is still a good approach, I am good with it
      //  move this class buttonsContainerClassName style to the component and make it a grid with row spacing
      return (
        <div className={buttonsContainerClassName}>
          {...extraButtons}
          {closeButton}
        </div>
      );
    }
    return closeButton;
  };

  const callToAction = getCallToAction();
  return (
    <div className={s.wrapper}>
      {icon && (
        <div className={s.iconWrapper}>
          <DynamicIcon name={icon} color={iconColor} fontSize={7.2} />
        </div>
      )}
      {image && image}
      {title && <p className={s.title}>{title}</p>}
      {warning && <DynamicWarningPill warning={warning} />}
      {bodyPlacement === 'BEFORE_CTA' && body}
      {callToAction}
      {bodyPlacement === 'AFTER_CTA' && body}
    </div>
  );
};

export default DialogBody;
