import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';

import {ERROR_LEVEL, FALLBACK_RESTRICTED_COUNTRIES, FALLBACK_RESTRICTED_COUNTRIES_DEV_ENV} from '@/constants';
import {useAppConfig} from '@/contexts/AppConfigContext';
import {tracking} from '@/services/tracking/TrackingService';

import CountryNotAvailableComponent from '@/components/CountryNotAvailable';
import Splash from '@/components/Splash/Splash';

import type {NextRequest} from 'next/server';
import type {PropsWithChildren} from 'react';

const LocationControl = ({children}: PropsWithChildren) => {
  const router = useRouter();
  const {appConfig, loadingAppConfig} = useAppConfig();
  const [location, setLocation] = useState<NextRequest['geo'] | null>(null);
  const [restrictedCountries, setRestrictedCountries] = useState<string[]>(
    process.env.NODE_ENV === 'development'
      ? FALLBACK_RESTRICTED_COUNTRIES_DEV_ENV
      : FALLBACK_RESTRICTED_COUNTRIES,
  );
  const [locationControlDone, setLocationControlDone] = useState(false);
  const [isUnavailableCountry, setIsUnavailableCountry] = useState(false);

  useEffect(() => {
    if (!loadingAppConfig && appConfig.restrictedCountries) {
      setRestrictedCountries(appConfig.restrictedCountries);
    }
  }, [appConfig.restrictedCountries, loadingAppConfig]);

  useEffect(() => {
    if (!loadingAppConfig && !location) {
      const fetchAndSetLocation = async () => {
        try {
          const response = await fetch('/api/location');
          const location = await response.json();
          setLocation(location?.geo);
        } catch (error: any) {
          tracking.logError({
            error_message: error.message || JSON.stringify(error),
            error_level: ERROR_LEVEL.NOTICE,
            error_message_id: 'auth/kasta/could-not-detect-user-location-or-ip',
          });
        }
      };
      fetchAndSetLocation();
    }
  }, [appConfig, loadingAppConfig, location]);

  useEffect(() => {
    if (!location || !restrictedCountries) return;

    // Location info is not able in local development
    const isDevelopment = process.env.NODE_ENV === 'development';
    const cdnCountry = location.country;
    if (!cdnCountry && !isDevelopment) {
      setLocationControlDone(true);
      return;
    }

    const isLocationRestricted = restrictedCountries.some(c => c === cdnCountry);
    if (isLocationRestricted) {
      setIsUnavailableCountry(true);
    }
    setLocationControlDone(true);
  }, [restrictedCountries, location, router]);

  if (!locationControlDone) {
    return <Splash />;
  }

  if (isUnavailableCountry) {
    return <CountryNotAvailableComponent />;
  }

  return <>{children}</>;
};

export default LocationControl;
