// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"4fd94e7033087120d11cc4b13839882e3393c52f"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import packageJson from '@/package.json';
import * as Sentry from '@sentry/nextjs';

console.log('[Sentry] Client config read?', process.env.NEXT_PUBLIC_VERCEL_ENV);

Sentry.init({
  dsn: `${process.env.NEXT_PUBLIC_SENTRY_DSN}`,
  environment: `${process.env.NEXT_PUBLIC_VERCEL_ENV}`,
  release: `pwa@${packageJson.version}`,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ["Cannot read properties of undefined (reading 'subscriptionFailedCallback')"],

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      stickySession: true,
    }),
  ],
});
