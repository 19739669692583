import {useEffect, useState} from 'react';

function useIsPwaInstalled() {
  const [isPwaInstalled, setIsPwaInstalled] = useState(false);

  useEffect(() => {
    setIsPwaInstalled(window.matchMedia('(display-mode: standalone)').matches);
  }, []);

  return isPwaInstalled;
}

export default useIsPwaInstalled;
