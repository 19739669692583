import {useCallback} from 'react';

import {
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
  useQuery,
} from '@apollo/client';

/**
 * useLazyGraphQLQuery should be used when you need to control when the query is called.
 * It works better than using skip: true with useQuery alone since the error handling remains the same.
 */
export const useLazyGraphQLQuery = <TData, TVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  params: QueryHookOptions<TData, OperationVariables> = {},
) => {
  const {refetch} = useQuery(query, {
    ...params,
    skip: true,
    errorPolicy: 'all',
  });

  return useCallback(
    async (variables: Partial<TVariables> | undefined) => {
      const res = await refetch(variables);
      if (res.errors) {
        return undefined;
      }
      return res;
    },
    [refetch],
  );
};

export const useGraphQLClientForLazyQuery = <TData, TVariables extends OperationVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables>,
) => {
  return useLazyQuery(query, {
    ...options,
  });
};
