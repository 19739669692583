import mixpanel, {Dict, Query} from 'mixpanel-browser';

import {isDev} from '@/utils/device';

class MixPanel {
  constructor() {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
      debug: isDev,
      track_pageview: 'url-with-path-and-query-string',
      persistence: 'localStorage',
      api_host: 'https://api-eu.mixpanel.com',
      record_sessions_percent: 1, //records 1% of all sessions
    });
  }

  identify(id?: string) {
    mixpanel.identify(id);
  }

  alias(id: string) {
    mixpanel.alias(id);
  }

  track(event: string, props?: Dict) {
    mixpanel.track(event, props);
  }

  track_links(query: Query, name: string) {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    });
  }

  set(props: Dict) {
    mixpanel.people.set(props);
  }

  reset() {
    mixpanel.reset();
  }

  append(props: Dict) {
    mixpanel.people.append(props);
  }

  register(props: Dict) {
    mixpanel.register(props);
  }

  union(props: Dict) {
    mixpanel.people.union(props);
  }
}

// Ensure this code will only be available for client side
export const mixPanel: MixPanel | null = typeof window !== 'undefined' ? new MixPanel() : null;
