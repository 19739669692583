import React, {PropsWithChildren} from 'react';

import {createContext, useContextSelector} from 'use-context-selector';

import {useCashAuthProvider} from '@/hooks/useCashAuthProvider';
import {ScaScope} from '@/lib/constants';

import type {CashAuthContext} from '@/types/cashAuthContext';

export const CashBalanceAuthContext = createContext<CashAuthContext>({
  isCashAccountActivated: false,
  isSCARequired: false,
  isSCALoading: false,
  isSCADelayed: false,
  getIntergiroSCA: () => {},
  refetchSCAIfNeeded: () => {},
  refetchSCA: () => {},
  openSCALink: () => {},
  openSCAModal: () => {},
  closeSCAModal: () => {},
  data: undefined,
});

export const useGetCashAccountSCA = () => useContextSelector(CashBalanceAuthContext, c => c.getIntergiroSCA);

export const CashBalanceAuthProvider = ({children}: PropsWithChildren) => {
  const contextValues = useCashAuthProvider({
    scope: ScaScope.Balance,
  });

  return <CashBalanceAuthContext.Provider value={contextValues}>{children}</CashBalanceAuthContext.Provider>;
};
