import React, {MouseEventHandler, useState} from 'react';
import dynamic from 'next/dynamic';
import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {classNames} from 'primereact/utils';

import {ClickTrackingWrapper} from '@/components/Tracking/ClickTrackingWrapper';
import {ICON_COLORS} from '@/components/ui/Icon';

import type {AnalyticsEvent} from '@/types/tracking';
import type {UrlObject} from 'url';

import IconSvg, {IconSvgProps} from '../../IconSvg';
import s from './MainButton.module.scss';

const DynamicIcon = dynamic(() => import('@/components/ui/Icon'));
const Link = dynamic(() => import('next/link'));

type COLOR = keyof typeof COLOR_STYLES;
type VARIANT = keyof typeof VARIANTS_STYLES;
type SIZE = keyof typeof SIZES_STYLES;
type BACKGROUND_COLOR = keyof typeof BACKGROUND_COLOR_STYLES;
type ICON_COLOR = keyof typeof ICON_COLORS;

export interface MainButtonProps {
  label?: string;
  id?: string;
  type?: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  variant?: VARIANT;
  size?: SIZE;
  color?: COLOR;
  fullWidth?: boolean;
  leftIcon?: string | IconSvgProps;
  rightIcon?: string;
  iconFontSize?: number;
  iconColor?: ICON_COLOR;
  onClick?: MouseEventHandler | Function;
  asLink?: boolean;
  role?: string;
  href?: string | UrlObject;
  backgroundColor?: BACKGROUND_COLOR;
  shadow?: boolean;
  analyticEvent?: AnalyticsEvent;
}

const VARIANTS_STYLES = {
  PRIMARY: s.primary,
  SECONDARY: `${s.secondary} p-button-outlined`,
  TERTIARY: `${s.tertiary} p-button-text`,
};

const SIZES_STYLES = {
  LARGE: s.large,
  MEDIUM: s.medium,
  SMALL: s.small,
  XSMALL: s.xsmall,
  ICON: s.icon,
};

const COLOR_STYLES = {
  WHITE: s.colorWhite,
  PRIMARY: s.colorPrimary,
};

const BACKGROUND_COLOR_STYLES = {
  DEFAULT: '',
  WHITE: s.backgroundColorPaper,
  PRIMARY: s.backgroundColorPrimary,
};

const renderIcon = (icon: undefined | string | IconSvgProps, fontSize: number, color?: ICON_COLOR) => {
  if (!icon) return null;

  if (typeof icon === 'string') {
    return <DynamicIcon name={icon} fontSize={fontSize} color={color} />;
  }
  return <IconSvg {...icon} />;
};

const getFinalColor = (color: COLOR | undefined, variant: VARIANT): COLOR => {
  if (color) {
    return color;
  }
  if (variant === 'PRIMARY') {
    return 'WHITE';
  }
  return 'PRIMARY';
};

export default function MainButton({
  label,
  id,
  type = 'button',
  variant = 'PRIMARY',
  size = 'MEDIUM',
  color,
  fullWidth = false,
  className,
  disabled,
  loading,
  leftIcon,
  rightIcon,
  iconFontSize = 1.7,
  iconColor,
  href = '',
  role,
  onClick,
  backgroundColor,
  shadow = true,
  analyticEvent,
}: MainButtonProps) {
  const finalColor = getFinalColor(color, variant);
  const [_loading, setLoading] = useState(Boolean(loading));
  const isLoading = _loading || !!loading;

  const handleOnClick = async (e: React.MouseEvent<HTMLElement>) => {
    setLoading(true);
    e.preventDefault();
    await onClick?.(e);
    setLoading(false);
  };

  const classnames = classNames(
    className,
    s.wrapper,
    VARIANTS_STYLES[isLoading && variant !== 'TERTIARY' ? 'SECONDARY' : variant],
    SIZES_STYLES[size],
    {
      [s.onlyIcon]: !label,
      [COLOR_STYLES[finalColor]]: finalColor,
      [BACKGROUND_COLOR_STYLES[backgroundColor || 'DEFAULT']]: backgroundColor,
      [s.fullWidth]: fullWidth,
      [s.noShadow]: !shadow,
    },
  );

  const renderContent = () => (
    <>
      {renderIcon(leftIcon, iconFontSize, iconColor)}
      <span id={id ? `${id}-label` : undefined}>{label}</span>
      {renderIcon(rightIcon, iconFontSize, iconColor)}
    </>
  );

  return href ? (
    <ClickTrackingWrapper
      WrappedComponent={Link}
      // Link props
      className={`p-button p-component ${classnames}`}
      href={href}
      role={label}
      // tracking event
      analyticEvent={analyticEvent}
      id={id}>
      {renderContent()}
    </ClickTrackingWrapper>
  ) : (
    <ClickTrackingWrapper
      WrappedComponent={Button}
      // Button props
      type={type as 'button' | 'submit' | 'reset' | undefined}
      id={id}
      role={role || label}
      aria-label={label}
      disabled={disabled || isLoading}
      onClick={type !== 'submit' ? handleOnClick : undefined}
      className={classnames}
      // tracking event
      analyticEvent={analyticEvent}>
      {isLoading ? (
        <div>
          <ProgressSpinner
            style={{height: `${iconFontSize}rem`, width: `${iconFontSize}rem`}}
            strokeWidth="6"
          />
        </div>
      ) : (
        renderContent()
      )}
    </ClickTrackingWrapper>
  );
}
