import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';

import type {ReactNode} from 'react';

type PrivateModeContextType = {
  isPrivateMode?: boolean;
  togglePrivateMode?: Function;
  setValue?: Function;
};

const PrivateModeProcess = createContext<PrivateModeContextType>({});

export const usePrivateModeContext = () => useContext(PrivateModeProcess);

export const PrivateModeProvider = ({children}: {children: ReactNode}) => {
  const [isPrivateMode, setIsPrivateMode] = useState<boolean>(false);

  const togglePrivateMode = () => {
    const newBoolValue = (!isPrivateMode).toString();
    localStorage.setItem('privateModeEnabled', newBoolValue);
    setIsPrivateMode((prev: boolean) => !prev);
  };

  const updateValueFromLocalStorage = useCallback(() => {
    const isPrivateModeEnabled = localStorage.getItem('privateModeEnabled');
    setIsPrivateMode(isPrivateModeEnabled ? JSON.parse(isPrivateModeEnabled) : false);
  }, []);

  useEffect(() => {
    updateValueFromLocalStorage();
  }, []);

  const setValue = useCallback(
    (value: boolean) => {
      // If value is true means SCA is mandatory and therefore needs to force this context value to enable Private Mode
      if (value) {
        setIsPrivateMode(value);
        return;
      }
      // Otherwise this context should read from local storage based on user preference
      updateValueFromLocalStorage();
    },
    [setIsPrivateMode],
  );

  return (
    <PrivateModeProcess.Provider value={{isPrivateMode, togglePrivateMode, setValue}}>
      {children}
    </PrivateModeProcess.Provider>
  );
};
