import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import {
  lastServiceWorkerUpdateCheck,
  ONE_DAY_IN_MS,
  ONE_HOUR_IN_SEC,
  ONE_SECOND,
  RESEND_SMS_MINUTES_WAIT_TIME,
  TIME_FRAME_OPTIONS,
} from '@/constants';

import type {IsoDateString} from '@/types/generics';
import type {Dayjs} from 'dayjs';

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(isTomorrow);
dayjs.extend(utc);

type CountdownArgs = {
  timeoutEnds?: Dayjs;
  callback: Function;
};

export const createResendCooldownDate = (minutes = RESEND_SMS_MINUTES_WAIT_TIME) =>
  dayjs().add(minutes, 'minute');

const DATE_FORMAT_OPTIONS = {
  MINUTE_SECONDS: 'mm[min] ss[s]', // e.g. "05min 30s"
  HOUR_MINUTES: 'H[h] mm[min]',
  FULLSTRINGDATE_SHORTTIME: 'DD MMM YYYY, HH:mm', // e.g. "01 Jan 2022, 13:45"
  FULLDATE_SHORTTIME: 'DD.MM.YYYY, HH:mm', // e.g. "01.01.2022, 13:45"
  FULLTIME_FULLDATE: 'HH:mm:ss DD.MM.YYYY', // e.g. "13:45:00 01.01.2022"
  FULLDATE: 'DD.MM.YYYY', // e.g. "01.01.2022"
  TIME: 'HH:mm', // e.g. "13:45"
  FULLSTRINGDATE_SLASH: 'DD/MM/YYYY', // e.g. "01/02/2022"
} as const;

export type DATE_FORMAT_OPTIONS_TYPES = keyof typeof DATE_FORMAT_OPTIONS;

export const getFormattedDate = (time: string | number, formatOption: DATE_FORMAT_OPTIONS_TYPES) => {
  return dayjs(time).format(DATE_FORMAT_OPTIONS[formatOption]);
};

export const getFormattedDuration = (
  time: duration.DurationUnitsObjectType,
  formatOption: DATE_FORMAT_OPTIONS_TYPES,
) => {
  return dayjs.duration(time).format(DATE_FORMAT_OPTIONS[formatOption]);
};

/**
 * Triggers callback with formatted remainingTime once a second
 * until remainingTime reachs 0
 */
export const handleCountdownTime = ({timeoutEnds, callback}: CountdownArgs) => {
  calculateTimeTriggerCallback({timeoutEnds, callback});
  const intervalHandler = setInterval(() => {
    const remainingTime = calculateTimeTriggerCallback({timeoutEnds, callback});
    if (remainingTime <= 0) {
      clearInterval(intervalHandler);
    }
  }, ONE_SECOND);

  return intervalHandler;
};

/**
 * Calculate remaining time and trigger calback
 */
const calculateTimeTriggerCallback = ({timeoutEnds, callback}: CountdownArgs) => {
  const remainingTime = dayjs(timeoutEnds).diff(dayjs());
  if (remainingTime >= 0) {
    callback?.(remainingTime);
  }
  return remainingTime;
};

export const getDateRangeByTimeFrame = (timeFrameOption: any) => {
  const maxDate = dayjs().toISOString();
  let minDate;
  switch (timeFrameOption?.timeFrame) {
    case TIME_FRAME_OPTIONS.day.timeFrame:
    case TIME_FRAME_OPTIONS.week.timeFrame:
    case TIME_FRAME_OPTIONS.month.timeFrame:
      minDate = dayjs().subtract(1, timeFrameOption.timeFrame).toISOString();
      break;
    default:
      // TIME_FRAME_OPTIONS.year or TIME_FRAME_OPTIONS.all
      minDate = dayjs().subtract(1, 'year').toISOString();
  }
  return {minDate, maxDate};
};

export const isTimeoutActive = (timeoutEnds?: Dayjs) => {
  return timeoutEnds && dayjs().isBefore(timeoutEnds);
};

export const getMinimunYearsOldDate = () => {
  const today = new Date();
  const year = today.getFullYear() - 18;
  const month = today.getMonth();
  const day = today.getDate() - 1;
  return new Date(year, month, day);
};

export const isTodayOrYesterday = (date: IsoDateString) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const dateToCheck = new Date(date);

  if (dateToCheck.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (dateToCheck.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else {
    return '';
  }
};

/**
 * Determines whether the Service Worker update check should be performed.
 *
 * This function checks the timestamp of the last update check stored in
 * `localStorage`. If the last check was more than 24 hours ago, or if no
 * check has been performed yet, it returns `true` and updates the stored
 * timestamp to the current time.
 *
 * @returns {boolean} `true` if an update check should be performed, `false` otherwise.
 */
export const shouldCheckForServiceWorkerUpdate = (): boolean => {
  const lastUpdateCheck = localStorage.getItem(lastServiceWorkerUpdateCheck);
  const now = Date.now();

  if (!lastUpdateCheck || now - parseInt(lastUpdateCheck, 10) > ONE_DAY_IN_MS) {
    localStorage.setItem(lastServiceWorkerUpdateCheck, now.toString());
    return true;
  }

  return false;
};

/**
 * Get formatted date for next attempt
 * @timestamp - string with date in utc
 */
export const getNextAttemptFormatted = (timestamp: string) => {
  const secondsDiff = dayjs(timestamp).diff(dayjs(), 'seconds');
  if (secondsDiff < ONE_HOUR_IN_SEC) {
    return `Please try again in ${getFormattedDuration({minutes: Math.trunc(secondsDiff / 60), seconds: secondsDiff % 60}, 'MINUTE_SECONDS')}.`;
  }
  if (dayjs(timestamp).isTomorrow()) {
    return 'Please, try again tomorrow.';
  }
  if (secondsDiff < 24 * ONE_HOUR_IN_SEC) {
    return `Please try again in ${getFormattedDuration(
      {
        hours: Math.trunc(secondsDiff / ONE_HOUR_IN_SEC),
        minutes: Math.trunc(secondsDiff % 60),
      },
      'HOUR_MINUTES',
    )}.`;
  }
  return `Please try again at ${getFormattedDate(timestamp, 'FULLSTRINGDATE_SLASH')}.`;
};
