import React, {PropsWithChildren} from 'react';

import {createContext} from 'use-context-selector';

import {ScaScope} from '@/constants';
import {useCashAuthProvider} from '@/hooks/useCashAuthProvider';

import type {CashAuthContext} from '@/types/cashAuthContext';

export const CashHistoryAuthContext = createContext<CashAuthContext>({
  isCashAccountActivated: false,
  isSCARequired: false,
  isSCALoading: false,
  isSCADelayed: false,
  getIntergiroSCA: () => {},
  refetchSCAIfNeeded: () => {},
  refetchSCA: () => {},
  openSCALink: () => {},
  openSCAModal: () => {},
  closeSCAModal: () => {},
  data: undefined,
});

export const CashHistoryAuthProvider = ({children}: PropsWithChildren) => {
  const contextValues = useCashAuthProvider({
    scope: ScaScope.History,
  });

  return <CashHistoryAuthContext.Provider value={contextValues}>{children}</CashHistoryAuthContext.Provider>;
};
