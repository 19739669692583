import type {GetTransactionsQuery, Transaction} from '@/graphql/__generated__/graphql';
import type {TransactionsArray} from '@/types/transactions';

export const afterGet = (transactions: GetTransactionsQuery | undefined) => {
  if (!transactions?.getTransactions?.items) return [] as TransactionsArray;
  const filteredTransactions = transactions.getTransactions.items.filter((t): t is Transaction => t !== null);
  const nonNullableTransactions = filteredTransactions;
  return nonNullableTransactions;
};

export const lastKeyAfterGet = (transactions: GetTransactionsQuery | undefined) => {
  return transactions?.getTransactions?.lastKey || null;
};
