import {AssetType, BlockchainAsset, Currency, ExchangeRates} from '@/graphql/__generated__/graphql';
import {BigNumber} from 'bignumber.js';

import {LEADING_ZEROS, TRAILING_AFTER_DECIMAL_ZEROS} from '@/constants';

import type {EnterSwapAmountProps} from '@/components/convert/EnterSwapAmount/EnterSwapAmount';
import type {EmptyObject} from '@/types/generics';

import {transformSupportedAssets} from './assets';

interface ConvertedAndFormattedValuesProps {
  sourceCurrency: Currency;
  destCurrency: Currency;
  value: string;
  exchangeRates: ExchangeRates | EmptyObject;
  usdExchangeRate?: number | null;
  supportedBlockchainAssets: BlockchainAsset[];
}

export interface ConvertedAndFormattedValues {
  originalValue: string;
  originalValueFormatted: string;
  convertedValue: string;
  convertedValueFormatted: string;
  exchangedRateFormattedValue: string;
  exchangedRateFormatted: string;
}

interface ConvertedAmountProps {
  amount: number | string | null;
  from: Currency | string | null;
  to: Currency | string | null;
  exchangeRates: ExchangeRates;
  usdExchangeRate: number | null;
  convertFeeRate: number | null;
}

export type ToFixedArgs = {
  value: number | string | null;
  decimals?: number;
};

export type getConvertedAmountArgs = Partial<ConvertedAmountProps>;

export type getFormattedAmountArgs = Intl.NumberFormatOptions & {
  currency: string;
  value?: number | string | BigNumber;
  hideSeparator?: boolean;
  decimalsLength?: number;
  supportedBlockchainAssets?: BlockchainAsset[];
  omitCurrencySymbol?: boolean;
  locale?: string;
};

export const hideAmount = () => '*****';

export const toFixed = ({value = 0, decimals}: ToFixedArgs) => {
  const amount = new BigNumber(value || 0);
  return amount
    .toFormat(decimals || 0, BigNumber.ROUND_HALF_DOWN)
    .toString()
    .replace(/,/g, '');
};

export const trimTrailingZeros = (value: number | string) => {
  let valueString = value.toString();
  if (valueString.indexOf('.') === -1) {
    return value;
  }
  return valueString.replace(TRAILING_AFTER_DECIMAL_ZEROS, '$1');
};

export const trimLeadingZeros = (value: string) => {
  if (value.indexOf('.') === -1) {
    return value.replace(LEADING_ZEROS, '');
  }
  return value;
};

export const formatAmount = ({value = '', decimals}: {value: string; decimals: number}) => {
  value = trimLeadingZeros(value);
  const dotPosition = value?.indexOf('.');
  if (dotPosition < 0) {
    return value;
  }
  const shouldFormat = value?.length - dotPosition > decimals;
  const result = shouldFormat ? toFixed({value, decimals}) : value;
  return result;
};

export function getConvertedAmount({
  amount,
  from,
  to,
  exchangeRates,
  usdExchangeRate,
  convertFeeRate,
}: getConvertedAmountArgs) {
  const isToUSD = to === Currency.Usd;
  const areValidExchangeRates = Boolean(exchangeRates) && typeof exchangeRates === 'object';
  const invalidExchangeRate =
    (!areValidExchangeRates && !usdExchangeRate) || (!areValidExchangeRates && !isToUSD);

  if (!amount || !from || !to || invalidExchangeRate) {
    return '0';
  }

  if (from === to) {
    return amount;
  }

  let resultAmount = '0';

  if (isToUSD && usdExchangeRate) {
    resultAmount = new BigNumber(amount).multipliedBy(usdExchangeRate).toString();
  } else if (areValidExchangeRates) {
    const exchangeRate = exchangeRates[(from + to) as keyof ExchangeRates];

    const toFromRate = exchangeRates[(to + from) as keyof ExchangeRates];
    const fallbackRate = toFromRate ? new BigNumber(1).dividedBy(toFromRate).toString() : 0;

    resultAmount = new BigNumber(amount).multipliedBy(exchangeRate || fallbackRate).toString();
  }
  if (convertFeeRate) {
    resultAmount = subtractFee(resultAmount, convertFeeRate);
  }

  // Avoid returning Infinity or NaN
  return resultAmount === 'Infinity' || resultAmount === 'NaN' ? '0' : resultAmount;
}

export function subtractFee(amount: string, feeRate: number) {
  if (feeRate > 100) {
    return '0';
  }
  return new BigNumber(amount).minus(new BigNumber(feeRate).dividedBy(100).multipliedBy(amount)).toString();
}

export function getFormattedAmount({
  currency,
  value = 0,
  currencyDisplay = 'symbol',
  minimumIntegerDigits = 2,
  signDisplay = 'auto',
  hideSeparator,
  supportedBlockchainAssets = [], // only necessary to format crypto
  omitCurrencySymbol,
  locale,
  ...settings
}: getFormattedAmountArgs) {
  const amount = BigNumber(value);

  const supportedAssets = transformSupportedAssets(supportedBlockchainAssets) as {
    [key in Currency]: BlockchainAsset;
  };

  let decimalsLength = settings?.decimalsLength;

  // If crypto currency
  if (supportedAssets?.[currency as Currency]?.type === AssetType.Crypto) {
    if (Number(amount) === 0) {
      return `0 ${settings.style ? '' : `${currency.toUpperCase()}`}`;
    }

    if (!decimalsLength) {
      decimalsLength = +supportedAssets[currency as Currency].decimalDigits;
    }
    return `${trimTrailingZeros(amount.toFormat(decimalsLength, BigNumber.ROUND_DOWN))}${
      settings.style ? '' : ` ${currency.toUpperCase()}`
    }`;
  }

  try {
    const language = locale || 'en'; // update when using i18n
    const formattedAmount = new Intl.NumberFormat(language, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: decimalsLength || 2,
      signDisplay,
      useGrouping: true,
      ...settings,
    }).format(Number(amount));

    if (hideSeparator) {
      return formattedAmount.replace(/,/g, ' ');
    }
    return formattedAmount;
  } catch (error) {
    return `${currency} ${value}`;
  }
}

export const getFormattedPercentage = ({value = 0, ...options}) => {
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  }).format(value);

  return formattedAmount;
};

// According to the product decision, the default `from` currency is USDT and the default `to` currency is KASTA.
// If either `from` or `to` currency is not explicitly declared, the function will use the default value for the missing currency.
// If both `from` and `to` currencies are declared, the function will use them as is.
// If only one of the `from` or `to` currencies is declared, the function will use the declared currency as is, and use the default value for the missing currency.
// If selected one is the contrary value's default currency we use the other default instead
// For example, if the `from` currency is declared as USDT and the `to` currency is missing, the function will use the default value for `to`, which is KASTA.
export const getDefaultFromOrTo = (
  targetDefault: 'from' | 'to',
  from: EnterSwapAmountProps['from'],
  to: EnterSwapAmountProps['to'],
  isCashAccountActivated: boolean,
) => {
  if (targetDefault === 'from') {
    if (isCashAccountActivated) {
      return from || (to !== Currency.Eur ? Currency.Eur : Currency.Btc);
    }
    return from || (to !== Currency.Btc ? Currency.Btc : Currency.Usdt);
  }
  if (isCashAccountActivated) {
    return to || (from !== Currency.Btc ? Currency.Btc : Currency.Eur);
  }
  return to || (from !== Currency.Usdt ? Currency.Usdt : Currency.Btc);
};

export const getConvertedAndFormattedValues = ({
  sourceCurrency,
  destCurrency,
  value,
  exchangeRates,
  usdExchangeRate,
  supportedBlockchainAssets,
}: ConvertedAndFormattedValuesProps): ConvertedAndFormattedValues => {
  const originalValueFormatted = getFormattedAmount({
    currency: sourceCurrency,
    value,
    supportedBlockchainAssets,
  });

  const convertedValue = getConvertedAmount({
    amount: value,
    from: sourceCurrency,
    to: destCurrency,
    exchangeRates,
    usdExchangeRate,
  }).toString();

  const convertedValueFormatted = getFormattedAmount({
    currency: destCurrency,
    value: convertedValue,
    supportedBlockchainAssets,
  });

  const fromTo = (sourceCurrency + destCurrency) as keyof ExchangeRates;
  const rate =
    destCurrency === Currency.Usd && usdExchangeRate ? usdExchangeRate : exchangeRates?.[fromTo] || 0;

  const exchangedRateFormattedValue = getFormattedAmount({
    value: rate,
    currency: destCurrency,
  });

  const exchangedRateFormatted = `1 ${sourceCurrency} = ${exchangedRateFormattedValue}`;
  return {
    originalValue: value,
    originalValueFormatted,
    convertedValue,
    convertedValueFormatted,
    exchangedRateFormattedValue,
    exchangedRateFormatted,
  };
};
