import {createContext, useContext, useEffect, useState} from 'react';
import {useEventListener, useMountEffect, useResizeListener} from 'primereact/hooks';

import useIsTouchDevice from '@/hooks/useIsTouchDevice';
import {isChromeInIOS, isIOSSafari} from '@/utils/device';

import type {ReactNode} from 'react';

type VirtualKeyboardContextType = {
  isOpenVirtualkeyboard: boolean;
};

const VirtualKeyboardContext = createContext<VirtualKeyboardContextType>({
  isOpenVirtualkeyboard: false,
});
export const useVirtualKeyboardContext = () => useContext(VirtualKeyboardContext);

const VirtualKeyboardContextProvider = ({children}: {children: ReactNode}) => {
  const isTouchDevice = useIsTouchDevice();
  const [originalDeviceHeight, setOriginalDeviceHeight] = useState(0);
  const [isOpenVirtualkeyboard, setIsOpenVirtualkeyboard] = useState(false);

  const handleOnResize = (event: Event) => {
    if (!isTouchDevice) {
      return;
    }

    const evTarget = event.currentTarget as Window;
    const newHeight = evTarget?.visualViewport?.height || evTarget?.innerHeight;

    if (!isOpenVirtualkeyboard && newHeight < originalDeviceHeight) {
      setIsOpenVirtualkeyboard(true);
    }

    if (isOpenVirtualkeyboard && newHeight === originalDeviceHeight) {
      setIsOpenVirtualkeyboard(false);
    }
  };
  const handleOnFocusIn = () => {
    if (!isOpenVirtualkeyboard) {
      setIsOpenVirtualkeyboard(true);
    } else {
      // Sometimes when focusin happen from a focused input to another input
      // onfocusout is not fired so we imitate the expected behavior
      setIsOpenVirtualkeyboard(false);
      setTimeout(() => setIsOpenVirtualkeyboard(true));
    }
  };

  // setTimeout ensures button clicks are executed before
  // changes triggered by setIsOpenVirtualkeyboard have been propagated
  const handleOnFocusOut = () => setTimeout(() => setIsOpenVirtualkeyboard(false));

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: handleOnResize,
    when: !isIOSSafari() && !isChromeInIOS(),
  });
  const [bindWindowOnFocusInListener, unbindWindowFocusInListener] = useEventListener({
    type: 'focusin',
    listener: handleOnFocusIn,
    target: 'window',
    when: isIOSSafari() || isChromeInIOS(),
  });
  const [bindWindowOnFocusOutListener, unbindWindowFocusOutListener] = useEventListener({
    type: 'focusout',
    listener: handleOnFocusOut,
    target: 'window',
    when: isIOSSafari() || isChromeInIOS(),
  });

  useMountEffect(() => {
    const originalHeight = window?.visualViewport?.height || window.innerHeight;
    setOriginalDeviceHeight(originalHeight);
  });

  useEffect(() => {
    bindWindowOnFocusInListener();
    bindWindowOnFocusOutListener();
    bindWindowResizeListener();
    return () => {
      unbindWindowFocusInListener();
      unbindWindowFocusOutListener();
      unbindWindowResizeListener();
    };
  }, [
    bindWindowOnFocusInListener,
    bindWindowOnFocusOutListener,
    bindWindowResizeListener,
    unbindWindowFocusInListener,
    unbindWindowFocusOutListener,
    unbindWindowResizeListener,
  ]);

  return (
    <VirtualKeyboardContext.Provider
      value={{
        isOpenVirtualkeyboard,
      }}>
      {children}
    </VirtualKeyboardContext.Provider>
  );
};

export default VirtualKeyboardContextProvider;
