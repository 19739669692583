import {UserStatus} from '@/graphql/__generated__/graphql';
import {ValueOf} from '@/types/generics';
import {ServiceApolloErrors, ServiceGeneralErrors, ServiceValidationErrors} from '@/types/serviceErrors';

export const AuthenticationMethod = {
  EMAIL: 'email',
  PHONE_NUMBER: 'phone number',
  GOOGLE: 'google',
} as const;

export type AuthenticationMethodType = ValueOf<typeof AuthenticationMethod>;

export type ValidateIdentifierProps = {identifier?: string | null; throwOn?: ThrowOn};
export type SendVerificationMechanismProps = {identifier?: string | null; operation?: Operation};
export type ValidateVerificationMechanismProps = {
  identifier?: string | null;
  operation?: Operation;
  code: string;
};

export type DataBaseUserEmailProps = {
  userStatus: string | null;
  userExistsInDB: boolean;
};

export type ThrowOn = 'user_already_exists' | 'auth_already_exists' | 'user_not_found';
export type Operation = 'sign_in' | 'link';

export type HandleAuthenticationErrorProps = {
  error: unknown;
  setError?: (message: string) => void;
  fallbackErrorMsg?: string;
  shouldThrow?: boolean;
  onIsNewUser?: () => void;
  onUserNotFound?: () => void;
  onUserNotLinked?: () => void;
  onUserAlreadyExists?: () => void;
  onUserStatusError?: (status: UserStatus) => void;
  onUserAlreadyLoggedIn?: () => void;
};

// Defines what should be present and static in base auth class
export abstract class IAuthenticationMethodAbstract {
  static THROW_ON: Record<string, ThrowOn>;
  static OPERATION: Record<string, Operation>;
  static VALIDATION_ERROR: ServiceValidationErrors;
  static APOLLO_ERROR: ServiceApolloErrors;
  static GENERAL_ERROR: ServiceGeneralErrors;
  static FIREBASE_ERROR: Record<string, string>;

  abstract shouldLinkMethod(): void;
  abstract clearShouldLinkStatus(): void;
  abstract getShouldLinkStatus(): boolean;

  abstract removeIdentifier(): void;
  abstract getIdentifier(): string | null;
  abstract saveIdentifier(identifier: string): void;

  abstract handleAuthenticationError(props: HandleAuthenticationErrorProps): void;

  abstract saveLastAuthenticationMethod(method: AuthenticationMethodType): void;
  abstract getLastAuthenticationMethod(): AuthenticationMethodType;
}

// These need to exist in the specific auth classes
export interface IAuthenticationMethod extends IAuthenticationMethodAbstract {
  COOLDOWN_TIME: number;

  VERIFICATION_MECHANISM_ICON: string;
  VERIFICATION_MECHANISM_ADVICE: string;

  METHOD: AuthenticationMethodType;

  validateIdentifier(props?: ValidateIdentifierProps): Promise<void>;
  sendVerificationMechanism(props?: SendVerificationMechanismProps): Promise<void>;
  validateVerificationMechanism(props?: ValidateVerificationMechanismProps): Promise<void>;

  saveLastAuthenticationMethod(): void;

  removeIdentifier(): void;
  getIdentifier(): string | null;
  saveIdentifier(identifier: string): void;

  doesUserExistsInFirebase(email: string): void;
  doesEmailExistsInDatabase(email: string): void;
  doesPhoneExistsInDatabase(phone: string): void;
}
