import {useMemo} from 'react';

import {BigNumber} from 'bignumber.js';

import {getFormattedAmount} from '@/utils/amounts';

import {BlockchainAsset, Currency} from '../graphql/__generated__/graphql';

type useFormattedAmountArgs = {
  currency: Currency;
  value: string | number | BigNumber;
  currencyDisplay?: string;
  minimumIntegerDigits?: number;
  availableAssets?: BlockchainAsset[];
  signDisplay?: string;
  hideSeparator?: boolean;
  settings?: any;
};

export function useFormattedAmount({
  currency,
  value = 0,
  currencyDisplay = 'symbol',
  minimumIntegerDigits = 2,
  availableAssets,
  signDisplay = 'auto',
  hideSeparator,
  settings = {},
}: useFormattedAmountArgs) {
  return useMemo(() => {
    if (!currency) {
      return '0';
    }
    return getFormattedAmount({
      currency,
      value: Number(value),
      currencyDisplay,
      minimumIntegerDigits,
      signDisplay: signDisplay as 'auto' | 'never' | 'always' | 'exceptZero',
      hideSeparator,
      supportedBlockchainAssets: availableAssets,
      ...settings,
    });
  }, [
    currency,
    currencyDisplay,
    minimumIntegerDigits,
    hideSeparator,
    settings,
    signDisplay,
    availableAssets,
    value,
  ]);
}
