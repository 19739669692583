import {useCallback, useEffect} from 'react';
import Router, {useRouter} from 'next/router';

import {goTo} from '@/utils/navigation';

const useClientOfflineControl = () => {
  const {pathname} = useRouter();

  const handleOffline = useCallback(() => {
    if (pathname !== '/_offline') {
      goTo('/_offline');
    }
  }, [pathname]);

  const handleOnline = () => Router.back();

  useEffect(() => {
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, [handleOffline]);
};

export default useClientOfflineControl;
