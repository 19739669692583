import {USER_IP, USER_LOCATION} from '@/constants';
import {GeoLocation} from '@/services/user/UserService';

export const getUserInfoStorage = () => {
  if (typeof localStorage !== 'undefined' && typeof JSON !== 'undefined') {
    const userLocale = localStorage.getItem(USER_LOCATION);
    const userIp = localStorage.getItem(USER_IP);

    return {
      geo: userLocale ? JSON.parse(userLocale) : '',
      ip: userIp ? JSON.parse(userIp) : '',
    };
  }

  return {
    geo: '',
    ip: '',
  };
};

export const setUserInfoStorage = (userLocale: GeoLocation | undefined = undefined, userIp: string = '') => {
  localStorage.setItem(USER_LOCATION, JSON.stringify(userLocale || ''));
  localStorage.setItem(USER_IP, JSON.stringify(userIp));
};
