import type {ReactNode} from 'react';

import {ApolloWrapper} from './ApolloProvider';
import {DialogProvider} from './DialogContext';
import NavigationContextProvider from './NavigationContext';
import {PrivateModeProvider} from './PrivateModeContext';
import {ToastNotificationProvider} from './ToastNotificationContext';
import VirtualKeyboardContextProvider from './VirtualKeyboardContext';

export type AppProvidersProps = {
  children: ReactNode;
  pageProps: any;
};

const AppProviders = ({pageProps, children}: AppProvidersProps) => {
  return (
    <ToastNotificationProvider>
      <ApolloWrapper pageProps={pageProps}>
        <NavigationContextProvider>
          <VirtualKeyboardContextProvider>
            <PrivateModeProvider>
              <DialogProvider>{children}</DialogProvider>
            </PrivateModeProvider>
          </VirtualKeyboardContextProvider>
        </NavigationContextProvider>
      </ApolloWrapper>
    </ToastNotificationProvider>
  );
};

export default AppProviders;
