import dayjs from 'dayjs';

export default class PinService {
  private readonly timeoutInSeconds: number;

  constructor() {
    this.timeoutInSeconds = process.env.NEXT_PUBLIC_PIN_TIMEOUT
      ? parseInt(process.env.NEXT_PUBLIC_PIN_TIMEOUT)
      : 60 * 60;
  }

  setPinSuccess() {
    window?.sessionStorage?.setItem('lastSuccessfulPin', new Date().toISOString());
  }

  getPinTimedOut(): boolean {
    let pinTimedOut = true;
    const lastSuccessfulPin = window?.sessionStorage?.getItem('lastSuccessfulPin');
    if (lastSuccessfulPin) {
      const currentTime = dayjs();
      const diff = currentTime.diff(lastSuccessfulPin, 'minutes');
      pinTimedOut = diff > this.timeoutInSeconds;
    }
    return pinTimedOut;
  }

  setRecoveryCode(code: string) {
    window?.sessionStorage?.setItem('recoveryCode', code);
  }

  getRecoveryCode() {
    return window?.sessionStorage?.getItem('recoveryCode');
  }
}

export const pinService = new PinService();
