import {useCallback, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {useMountEffect} from 'primereact/hooks';

import {QueryWithoutStringArray} from '@/types/generics';
import {onIdTokenChanged, signInAnonymously} from 'firebase/auth';

import {ERROR_LEVEL} from '@/constants';
import {fbAuth} from '@/lib/firebase';
import {tracking} from '@/services/tracking/TrackingService';
import {setFirebaseToken} from '@/services/user';

import type {User} from 'firebase/auth';

export type FBUser = User | null;

export const useFirebaseUser = () => {
  const {query} = useRouter();
  const [fbUser, setFbUser] = useState<FBUser>(null);
  const [setFBUserOnce, setSetFBUserOnce] = useState(false);

  const handleIdTokenChange = useCallback(
    async (user: FBUser) => {
      try {
        if (user?.uid !== fbUser?.uid || user?.isAnonymous !== fbUser?.isAnonymous) {
          setFbUser(user || null);
        }
        setSetFBUserOnce(true);

        const token = await user?.getIdToken();
        if (token) {
          await setFirebaseToken(token);
        }
      } catch (error) {
        tracking.logError({
          error_level: ERROR_LEVEL.CRITICAL,
          error_message: `${JSON.stringify(error)}`,
          error_message_id: 'auth/kasta/error-handling-id-token-change',
        });
      }
    },
    [fbUser?.isAnonymous, fbUser?.uid],
  );

  const handleAnonymouslySignIn = async () => {
    await signInAnonymously(fbAuth);
  };

  useMountEffect(() => {
    const {test} = query as QueryWithoutStringArray;

    // allows test user to avoid recaptcha
    fbAuth.settings.appVerificationDisabledForTesting =
      process.env.NODE_ENV !== 'production' && Boolean(test);
  });

  useEffect(() => {
    const handle = setInterval(
      async () => {
        try {
          const user = fbAuth.currentUser;
          // refresh token and setFirebaseToken will be trigger
          if (user) await user.getIdToken(true);
        } catch (error) {
          tracking.logError({
            error_level: ERROR_LEVEL.CRITICAL,
            error_message: `Convert: ${JSON.stringify(error)}`,
            error_message_id: 'auth/kasta/unable-to-refresh-firebase-token',
          });
        }
      },
      15 * 60 * 1000,
    );

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  useEffect(() => {
    if (setFBUserOnce && !fbAuth.currentUser) {
      handleAnonymouslySignIn();
    }
  }, [setFBUserOnce]);

  useEffect(() => {
    const unsubscribeIdToken = onIdTokenChanged(fbAuth, handleIdTokenChange);
    return () => {
      unsubscribeIdToken();
    };
  }, [handleIdTokenChange]);

  return {
    fbUser,
    setFBUserOnce,
  };
};
