import * as Sentry from '@sentry/react';

import {ERROR_LEVEL} from '@/constants';
import {tracking} from '@/services/tracking/TrackingService';

import {GeoLocation} from '../user/UserService';

interface Params {
  userId?: string;
  displayName?: string;
  location?: GeoLocation;
}

// The init() function has been removed in favor of the
// sentry.client.js file, which is the Sentry standard configuration approach.
export class ErrorMonitoringService {
  logError(error: any, _componentStack: string | undefined, _eventId: string) {
    // Do something with the error, e.g. log to an external API
    tracking.logError({
      error_message: error.message || JSON.stringify(error),
      error_level: ERROR_LEVEL.CRITICAL,
      error_message_id: 'error_boundary',
    });
  }

  setUser({userId, displayName = undefined, location}: Params) {
    Sentry.setUser({
      id: userId,
      username: displayName,
      ip_address: '{{auto}}',
      geo: {
        city: location?.city,
        country_code: location?.country,
        region: location?.region,
      },
    });
  }
}

export const errorMonitoringService = new ErrorMonitoringService();
