import {BlockchainAsset, GetSupportedBlockchainAssetsQuery} from '@/graphql/__generated__/graphql';

type SupportedBlockchainAssetsGraphqlResponse = GetSupportedBlockchainAssetsQuery | undefined;

export const afterGet = (supportedAssetsResponse: SupportedBlockchainAssetsGraphqlResponse) => {
  const supportedAssets = supportedAssetsResponse?.getSupportedBlockchainAssets || [];
  const nonNullableSupportedAssets = supportedAssets.filter(
    asset => asset !== null,
  ) as NonNullable<BlockchainAsset>[];
  return nonNullableSupportedAssets;
};
