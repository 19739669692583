import {useMemo} from 'react';

import {ApolloProvider, NormalizedCacheObject} from '@apollo/client';

import {APOLLO_STATE_PROP_NAME} from '@/constants';
import {initializeClientApollo} from '@/lib/apollo';

import type {AppProvidersProps} from './AppProviders';
import type {ToastNotificationContextType} from './ToastNotificationContext';
import type {IncomingMessage} from 'http';
import type {NextApiRequestCookies} from 'next/dist/server/api-utils';

import {useToastNotificationContext} from './ToastNotificationContext';

export type ApolloClientContext = {
  req?: IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
};

export const useApollo = (
  pageProps: AppProvidersProps['pageProps'],
  setToastNotification: ToastNotificationContextType['setToastNotification'],
) => {
  let state: NormalizedCacheObject | null = null;
  if (APOLLO_STATE_PROP_NAME in pageProps) {
    state = pageProps[APOLLO_STATE_PROP_NAME] as NormalizedCacheObject;
  }
  const store = useMemo(
    () => initializeClientApollo(state, setToastNotification),
    [setToastNotification, state],
  );
  return store;
};

export const ApolloWrapper = ({pageProps, children}: AppProvidersProps) => {
  const {setToastNotification} = useToastNotificationContext();
  const client = useApollo(pageProps, setToastNotification);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
