export const WHITE_COLOR = '#ffffff';
export const PRIMARY_COLOR = '#631ffe';
export const SECONDARY_COLOR = '#01d5bf';
export const ACCENT_COLOR = '#ffe35e';
export const ERROR_COLOR = '#f78585';
export const TEXT_BG_MEDIUM_COLOR = '#180840';
export const TEXT_COLOR = 'rgba(24, 8, 64, 0.68)';
export const TEXT_LIGHT_COLOR = 'rgba(24, 8, 64, 0.38)';
export const DISABLED = '#D1CED9';

export const BGCOLORS = {
  WHITE: '#ffffff',
  PRIMARY: '#631ffe',
  SECONDARY: '#01D5BF',
  TEXT: '#180840',
  ACCENT: '#FFE35E',
  BACKGROUND: '#F6F6F6',
  NEUTRAL: '#E8E6EC',
} as const;

export const BGCOLORS_INVERTED = {
  '#FFFFFF': 'WHITE',
  '#631FFE': 'PRIMARY',
  '#01D5BF': 'SECONDARY',
  '#180840': 'TEXT',
  '#FFE35E': 'ACCENT',
  '#F6F6F6': 'BACKGROUND',
  '#E8E6EC': 'NEUTRAL',
} as const;

export const TIERS_BG_COLORS = {
  0: 'WHITE',
  1: 'SECONDARY',
  2: 'SECONDARY',
  3: 'SECONDARY',
  4: 'ACCENT',
  5: 'ACCENT',
  6: 'ACCENT',
  7: 'PRIMARY',
  8: 'PRIMARY',
  9: 'PRIMARY',
  10: 'TEXT',
} as const;
