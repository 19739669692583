import {createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react';

import ToastNotification from '@/components/ui/ToastNotification/ToastNotification';

import type {Toast, ToastMessage, ToastProps} from 'primereact/toast';

type ToastConfigByProps = ToastProps;
type ToastConfigByRefShow = ToastMessage;

export type ToastNotificationContextType = {
  setToastNotification: ({
    config,
    messageConfig,
  }: {
    config?: ToastConfigByProps;
    messageConfig: ToastConfigByRefShow;
  }) => void;
};

export const ToastNotificationContext = createContext<ToastNotificationContextType>({
  setToastNotification: () => {},
});

export const useToastNotificationContext = () => useContext(ToastNotificationContext);

const defaultToastConfig: ToastConfigByProps = {
  position: 'top-center',
};

const defaultMessageConfig: ToastConfigByRefShow = {
  severity: 'info',
  closable: false,
};

export const ToastNotificationProvider = ({children}: {children: ReactNode}) => {
  const toast = useRef<Toast>(null);
  const [toastStateConfig, setToastStateConfig] = useState<ToastConfigByProps>({position: 'top-center'});
  const [toastMessageConfig, setToastMessageConfig] = useState<ToastConfigByRefShow>({});
  const [showNotification, setShowNotification] = useState(false);

  const setToastNotification = useCallback<ToastNotificationContextType['setToastNotification']>(
    newConfig => {
      setToastStateConfig({...defaultToastConfig, ...newConfig.config});
      setToastMessageConfig({...defaultMessageConfig, ...newConfig.messageConfig});
      setShowNotification(true);
    },
    [],
  );

  useEffect(() => {
    if (showNotification) {
      toast.current?.show(toastMessageConfig);
      setShowNotification(false);
    }
  }, [showNotification, toastMessageConfig]);

  return (
    <ToastNotificationContext.Provider value={{setToastNotification}}>
      <>
        <ToastNotification toastRef={toast} {...toastStateConfig} />
        {children}
      </>
    </ToastNotificationContext.Provider>
  );
};
