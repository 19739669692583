import IconSvg from '@/components/ui/IconSvg';

function Splash() {
  const imageHeight = 96;

  return (
    <main
      style={{
        height: '100vh',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <IconSvg size={imageHeight} name="Kasta_ShortIcon_Indigo_RGB" />
    </main>
  );
}

export default Splash;
