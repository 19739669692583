import {InternalError} from '@/errors/InternalError';
import {ValidationError} from '@/errors/ValidationError';
import {ServiceValidationErrors} from '@/types/serviceErrors';
import {GoogleAuthProvider} from '@firebase/auth';
import {FirebaseError} from 'firebase/app';
import {linkWithPopup, OAuthProvider, signInWithCredential} from 'firebase/auth';

import {ApolloClient} from '@apollo/client';

import {fbAuth} from '@/lib/firebase';

import TrackingService from '../tracking/TrackingService';
import {BaseAuthenticationMethod} from './BaseAuthenticationMethod';
import {
  AuthenticationMethod,
  IAuthenticationMethod,
  SendVerificationMechanismProps,
} from './IAuthenticationMethod';

export class GoogleAuthenticationService extends BaseAuthenticationMethod implements IAuthenticationMethod {
  public COOLDOWN_TIME = 0;
  public VERIFICATION_MECHANISM_ICON = '';
  public VERIFICATION_MECHANISM_ADVICE = '';
  public METHOD = AuthenticationMethod.GOOGLE;

  private provider = new GoogleAuthProvider();

  constructor(tracking: TrackingService, client: ApolloClient<any>) {
    super(tracking, client, 'google_authentication');
  }

  static VALIDATION_ERROR: ServiceValidationErrors = {
    ...super.VALIDATION_ERROR,
    USER_ALREADY_LOGGED_IN: {
      MSG: "It seems you're already logged in.",
      CODE: 'auth/kasta/user-already-logged-in',
    },
    COULD_NOT_GENERATE_CREDENTIAL_FROM_ERROR: {
      MSG: 'Could not generate credential from linking error. Please contact customer support.',
      CODE: 'auth/kasta/could-not-generate-credential-from-error',
    },
    COULD_NOT_SIGN_IN_WITH_CREDENTIAL: {
      MSG: 'Could not sign in with credential. Please contact customer support.',
      CODE: 'auth/kasta/could-not-sign-in-with-credential',
    },
  } as const;

  static GENERIC_ERROR = {
    VALIDATE_USER: 'Could not validate user. Please try again.',
    SIGN_IN: 'Could not sign in. Please try again.',
  } as const;

  public saveLastAuthenticationMethod(): void {
    super.saveLastAuthenticationMethod(AuthenticationMethod.GOOGLE);
  }

  public async validateIdentifier() {
    if (!fbAuth) {
      throw new InternalError({
        code: GoogleAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.CODE,
        message: GoogleAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.MSG,
      });
    }

    const fbUser = fbAuth.currentUser;
    if (!fbUser?.isAnonymous) {
      throw new ValidationError({
        message: GoogleAuthenticationService.VALIDATION_ERROR.USER_ALREADY_LOGGED_IN.MSG,
        code: GoogleAuthenticationService.VALIDATION_ERROR.USER_ALREADY_LOGGED_IN.CODE,
      });
    }
  }

  async sendVerificationMechanism({operation}: Pick<SendVerificationMechanismProps, 'operation'>) {
    try {
      if (!fbAuth || !fbAuth.currentUser) {
        throw new InternalError({
          code: GoogleAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.CODE,
          message: GoogleAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.MSG,
        });
      }

      fbAuth.useDeviceLanguage();

      // Should throw an error under most circumstances, that is expected!
      // We try to recover in various ways in the catch() clause.
      await linkWithPopup(fbAuth.currentUser, this.provider);

      // If an error isn't thrown AND we are linking the user,
      // mission accomplished.
      if (operation === GoogleAuthenticationService.OPERATION.LINK) {
        return;
      }

      // If an error isn't thrown but we aren't linking the user,
      // this is a user that tried to log in without signing up first.
      throw new ValidationError({
        code: GoogleAuthenticationService.VALIDATION_ERROR.IS_NEW_USER.CODE,
        message: GoogleAuthenticationService.VALIDATION_ERROR.IS_NEW_USER.MSG,
      });
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        try {
          await this.tryToRecoverFromLinkingError(error);
          return;
        } catch (e: unknown) {
          this.tracking.logServiceError({error: e, apolloErrors: GoogleAuthenticationService.APOLLO_ERROR});
          throw e;
        }
      }

      this.tracking.logServiceError({error, apolloErrors: GoogleAuthenticationService.APOLLO_ERROR});
      throw error;
    }
  }

  async validateVerificationMechanism() {
    console.log('For this authentication provider, this method does not need to be called.');
  }

  private async tryToRecoverFromLinkingError(error: FirebaseError) {
    const recoverableErrors = ['auth/email-already-in-use', 'auth/credential-already-in-use'];
    if (!recoverableErrors.includes(error.code)) {
      throw error;
    }

    const email = error.customData?.email as string;
    if (!email) {
      throw error;
    }
    const {hasProviders, hasEmailProvider, hasPhoneProvider, hasGoogleProvider} =
      await this.inspectUserProviders(email);
    if (hasPhoneProvider && !hasGoogleProvider) {
      this.shouldLinkMethod();
      throw new ValidationError({
        message: BaseAuthenticationMethod.VALIDATION_ERROR.USER_NOT_LINKED_IN_FB.MSG,
        code: BaseAuthenticationMethod.VALIDATION_ERROR.USER_NOT_LINKED_IN_FB.CODE,
      });
    }

    // This will happen when a user signs up via the app and only has a phone number provider.
    if (hasProviders && !hasEmailProvider) {
      throw new ValidationError({
        message: GoogleAuthenticationService.VALIDATION_ERROR.USER_NOT_LINKED_IN_FB.MSG,
        code: GoogleAuthenticationService.VALIDATION_ERROR.USER_NOT_LINKED_IN_FB.CODE,
      });
    }

    const credential = OAuthProvider.credentialFromError(error);
    if (!credential) {
      throw new ValidationError({
        code: GoogleAuthenticationService.VALIDATION_ERROR.COULD_NOT_GENERATE_CREDENTIAL_FROM_ERROR.CODE,
        message: GoogleAuthenticationService.VALIDATION_ERROR.COULD_NOT_GENERATE_CREDENTIAL_FROM_ERROR.MSG,
      });
    }

    try {
      await signInWithCredential(fbAuth, credential);
    } catch (e: unknown) {
      throw e;
    }
  }

  private async inspectUserProviders(email: string) {
    try {
      const response = await fetch(`/api/providers?email=${email}`);
      const providers = (await response.json()) as string[];

      const hasProviders = providers.length !== 0;
      const hasEmailProvider = providers.some(prov => ['password', 'emailLink', 'google.com'].includes(prov));
      const hasPhoneProvider = providers.some(prov => ['phone'].includes(prov));
      const hasGoogleProvider = providers.some(prov => ['google.com'].includes(prov));

      return {
        hasProviders,
        hasEmailProvider,
        hasPhoneProvider,
        hasGoogleProvider,
      };
    } catch (error: unknown) {
      return {
        hasProviders: false,
        hasEmailProvider: false,
        hasPhoneProvider: false,
        hasGoogleProvider: false,
      };
    }
  }
}
