import {useEffect} from 'react';

import {useAppConfig} from '@/contexts/AppConfigContext';
import {goTo} from '@/utils/navigation';

function useFeatureFlagRoutesControl(pathname: string) {
  const {appConfig} = useAppConfig();
  const {swapEnabledPwa, withdrawEnabledPwa} = appConfig;

  // TODO: discuss if we should centralize the "prevent route" behavior here
  useEffect(() => {
    if (pathname === '/convert' && !swapEnabledPwa) {
      goTo('/home');
      return;
    }
    if (pathname.startsWith('/withdraw') && !withdrawEnabledPwa) {
      goTo('/home');
      return;
    }
  }, [pathname, swapEnabledPwa, withdrawEnabledPwa]);
}

export default useFeatureFlagRoutesControl;
