import {useEffect, useState} from 'react';
import {useResizeListener} from 'primereact/hooks';

import {BREAKPOINTS} from '@/lib/constants';
import {isBrowser} from '@/utils/device';

import type {Breakpoints} from '@/types/business';

type Than = 'more' | 'less';

const getMediaCheck = (target: Breakpoints, than: Than, innerWidth: number) => {
  const isMore = than === 'more';
  const bpTarget = BREAKPOINTS[target];
  return Boolean(innerWidth && (isMore ? innerWidth > bpTarget : innerWidth < bpTarget));
};

//inspired solution to mismatch UI server / client error: https://www.benmvp.com/blog/handling-react-server-mismatch-error/
if (isBrowser()) {
  // @ts-ignore
  window.__WAS_SSR = true;
}

function useIsTargetMedia(target: Breakpoints, than: Than, nullWhileHydrating?: false): boolean;
function useIsTargetMedia(target: Breakpoints, than: Than, nullWhileHydrating: true): boolean | null;
function useIsTargetMedia(target: Breakpoints, than: Than, nullWhileHydrating?: boolean): boolean | null {
  // initialize the state from a value based on window
  // if it exists and not hydrating
  const [isTargetMedia, setIsTargetMedia] = useState(() => {
    // using lazy state initialization so that we only
    // read from window the very first render
    let initialIsTargetMedia = nullWhileHydrating ? null : false;

    // in addition to testing for window we also check
    // if the app wasn't server-side rendered. when it
    // wasn't, it's safe to get the window value
    // early here in state initialization
    // @ts-ignore
    if (isBrowser() && !window.__WAS_SSR) {
      initialIsTargetMedia = getMediaCheck(target, than, isBrowser() ? window.innerWidth : 0);
    }

    return initialIsTargetMedia;
  });

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: () => {
      setIsTargetMedia(getMediaCheck(target, than, window.innerWidth));
    },
  });

  useEffect(() => {
    bindWindowResizeListener();

    return () => {
      unbindWindowResizeListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsTargetMedia(getMediaCheck(target, than, window.innerWidth));
  }, [target, than]);

  return isTargetMedia;
}

export default useIsTargetMedia;
