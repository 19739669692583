import {UserStatus} from '@/graphql/__generated__/graphql';
import {ERROR_MESSAGE_ID as PossibleCodes} from '@/types/tracking';

import {ERROR_LEVEL} from '@/constants';

export class UserStatusError extends Error {
  status: UserStatus;
  code: PossibleCodes;
  level = ERROR_LEVEL.NOTICE;

  constructor({message, code, status}: {message: string; code: PossibleCodes; status: UserStatus}) {
    super(message);
    this.name = 'UserStatusError';
    this.code = code;
    this.status = status;
    Object.setPrototypeOf(this, UserStatusError.prototype);
  }
}
