import {BaseUser, EntryType, Transaction, TransactionType} from '@/graphql/__generated__/graphql';
import {BigNumber} from 'bignumber.js';

import type {TransactionsArray} from '@/types/transactions';

// SMART_EXCHANGE has been changed to EASY_SWAP in the BE,
// though it remains here for backwards compatability reasons.
// Remove when all data in the BE has been modified.
const getFinalElementType = (elementType: TransactionType | undefined) => {
  if (elementType === TransactionType.SmartExchange) {
    return TransactionType.EasySwap;
  }
  return elementType;
};

/**
 * Makes sure that counterPartyUser is defined. If a user has been deleted in the database,
 * GraphQL won't be able to resolve it.
 */
const getFinalCounterPartyUser = (counterPartyUser: BaseUser | undefined | null) => {
  return (
    counterPartyUser ?? {
      firstName: 'Unknown',
      lastName: 'Unknown',
      phoneNumber: 'Unknown',
      id: 'Unknown',
    }
  );
};

/**
 * Remove the SMART_EXCHANGE DEBIT and the TRANSFER related to it, but also updates the
 * SMART_EXCHANGE CREDIT with the TRANSFER user info related to it
 */
const filterTransactions = (transactions: TransactionsArray) => {
  if (!transactions) return [] as TransactionsArray;
  const businessFilteredTXs = transactions.filter((tx, _, array) => {
    const shouldFilterOut =
      !tx?.type ||
      (tx.type === TransactionType.EasySwap && tx.entryType === EntryType.Debit) ||
      (tx.type === TransactionType.Swap && tx.entryType === EntryType.Credit);
    if (shouldFilterOut) {
      return false;
    }

    if (tx?.type === TransactionType.Transfer && tx.entryType === EntryType.Credit) {
      const easySwapTransaction = array.find(
        item => item?.parentId === tx?.id && item.type === TransactionType.EasySwap,
      );
      if (easySwapTransaction) {
        return false;
      }
    }
    return true;
  });

  return businessFilteredTXs;
};

export function transformTransactionsList(transactions: TransactionsArray) {
  if (!transactions) return [] as TransactionsArray;

  const txsFirstMapping = transactions.map(element => {
    return {
      ...element,
      type: getFinalElementType(element?.type),
      counterPartyUser: getFinalCounterPartyUser(element?.counterPartyUser),
    };
  });
  return filterTransactions(txsFirstMapping as TransactionsArray);
}

// getSourceUsdExchangeRate is used in cases where the sourceCurrency and destCurrency are different,
// and you need the usdExchangeRate for the sourceCurrency. One such example is when calculating the fee
// for easy swaps which is often given in the sourceCurrency.
export function getSourceUsdExchangeRate({
  exchangeRate,
  usdExchangeRate,
}: {
  exchangeRate: Transaction['exchangeRate'];
  usdExchangeRate: Transaction['usdExchangeRate'];
}) {
  if (!exchangeRate || !usdExchangeRate) {
    return 0;
  }
  return new BigNumber(exchangeRate).multipliedBy(usdExchangeRate).toString();
}
